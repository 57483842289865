import "./AdminContent.scss";
import React, { useState, useCallback, useEffect } from "react";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import Search from "../../components/Search/Search";
import TableAdmin from "../../components/TableAdmin/TableAdmin";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import ModalContent from "../../components/ModalContent/ModalContent";
import axios from "axios";
import { configAxios, ROUTES_CONTENIDOS } from "../../utils/AxiosAPI";
import { fmtOptionsAutoComplete } from "../../utils/utils";
import Loading from "../../components/Loading/Loading";

const AdminContent = () => {
  const thead = ["Nombre", "Tipo", "No. descargables"];

  const [rowsContenidos, setRowsContenidos] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  const [autocoCompleteValue, setAutoCompleteValue] = useState("");

  const [contenido, setContenido] = useState({});
  const [contenidoEdit, setContenidoEdit] = useState({});
  const [hasPageLoaded, setHasPageLoaded] = useState(false);

  const [deleteModal, setModalDelete] = useState(false);
  const handleOpenDelete = data => {
    setModalDelete(true);
    setContenidoEdit(data);
  };
  const handleCloseDelete = () => {
    setModalDelete(false);
    setContenido({});
    setContenidoEdit({});
  };

  const [contentModal, setContentModal] = useState(false);
  const handleOpenContent = () => setContentModal(true);
  const handleCloseContent = () => {
    setContentModal(false);
    setContenido({});
    setContenidoEdit({});
  };

  const [contentModalEdit, setContentEdit] = useState(false);
  const handleOpenContentEdit = data => {
    setContentEdit(true);
    setContenidoEdit(data);
    setContenido(data);
  };
  const handleCloseContentEdit = () => {
    setContentEdit(false);
    setContenido({});
    setContenidoEdit({});
  };

  const autoCompleteOptions = fmtOptionsAutoComplete(
    rowsContenidos,
    "nombreContenido"
  );

  const getContenidos = async () => {
    try {
      const res = await axios.get(
        ROUTES_CONTENIDOS.getAllContenidos,
        configAxios
      );
      const data = res.data.data.sort((a, b) =>
        a.nombreContenido.localeCompare(b.nombreContenido)
      );
      setRowsContenidos(data);
      setFilteredData(data)
      setHasPageLoaded(true);
    } catch (err) {
      console.log(err);
    }
  };

  const createContenido = useCallback(async () => {
    const res = await axios.post(
      ROUTES_CONTENIDOS.createContenido,
      contenido,
      configAxios
    );
    if (res.status > 199 || res.status < 300) {
      handleCloseContent();
      getContenidos();
    }
  }, [contenido]);

  const handlerDelete = useCallback(async () => {
    try {
      const res = await axios.delete(
        `${ROUTES_CONTENIDOS.editDelete}${contenidoEdit.id}`,
        configAxios
      );
      if (res.status > 199 || res.status < 300) {
        handleCloseDelete();
        getContenidos();
      }
    } catch (err) {
      console.log(err);
    }
  }, [contenidoEdit]);

  
  const handlerEdit = useCallback(async () => {
    try {
      const res = await axios.put(
        `${ROUTES_CONTENIDOS.editDelete}${contenidoEdit.id}`,
        contenido,
        configAxios
      );
      if (res.status > 199 || res.status < 300) {
        handleCloseContentEdit();
        getContenidos();
      }
    } catch (err) {
      console.log(err);
    }
  }, [contenidoEdit, contenido]);

  const hadlerActive = async (id, active) => {
    const isActive = active === 1 ? 0 : 1
    try {
      const res = await axios.put(
        `${ROUTES_CONTENIDOS.editDelete}${id}`,
        {
          activo: isActive
        },
        configAxios
      );
      if (res.status > 199 || res.status < 300) {
        getContenidos();
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getContenidos();
  }, []);

  useEffect(() => {
    const filterdByDate = rowsContenidos
      .filter(row => {
        return autocoCompleteValue === ""
          ? row
          : row.nombreContenido === autocoCompleteValue;
      });

    setFilteredData(filterdByDate);
  }, [ autocoCompleteValue]);

  return (
    <main className="adminContent">
      <HeaderAdmin
        title="Administración de contenido"
        text="Lista de contenidos"
        bgColor="#009DFF"
      />
      <section className="adminContent__content">
        {hasPageLoaded ? (
          <>
            <Search
              label="Buscar un contenido"
              options={autoCompleteOptions}
              buttonTxt="Agregar nuevo contenido"
              action={handleOpenContent}
              autocoCompleteValue={autocoCompleteValue}
              setAutoCompleteValue={setAutoCompleteValue}
            />
            <TableAdmin
              colums={thead}
              rows={filteredData}
              editOpt={handleOpenContentEdit}
              deleteOpt={handleOpenDelete}
              activeOpt={hadlerActive}
              detail
              total="descargables"
            />

            <ModalDelete
              open={deleteModal}
              handleClose={handleCloseDelete}
              title="Eliminar contenido"
              name={contenidoEdit.nombreContenido}
              action={handlerDelete}
              txtAction="Eliminar contenido"
            />

            <ModalContent
              open={contentModal}
              handleClose={handleCloseContent}
              action={createContenido}
              data={contenido}
              setData={setContenido}
            />

            <ModalContent
              open={contentModalEdit}
              handleClose={handleCloseContentEdit}
              action={handlerEdit}
              data={contenido}
              setData={setContenido}
              edit
            />
          </>
        ) : (
          <Loading />
        )}
      </section>
    </main>
  );
};

export default AdminContent;
