import "./ContentDownloads.scss";
import React, { useEffect, useState } from "react";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faCirclePlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ModalDownload from "../../components/ModalDownload/ModalDownload";
import axios from "axios";
import {
  configAxios,
  ROUTES_CONTENIDOS,
  ROUTES_DESCARGABLES,
} from "../../utils/AxiosAPI";
import Loading from "../../components/Loading/Loading";
import ContenidosTable from "../../components/ContenidosTable/ContenidosTable";
import VisualizadorPDF from "../../components/VisualizadorPDF/VisualizadorPDF";
import IframeContainer2 from "../../components/IframeContainerContent/IframeContaine2";

const ContentDownloads = () => {
  const { id } = useParams();
  const [contenido, setContenido] = useState({});
  const [descargable, setDescargable] = useState({
    contenidos_id: id,
  });
  const [fileName, setFileName] = useState("Seleccionar un archivo");

  const [hasLoaded, setHasLoaded] = useState(false);

  const folder = contenido?.fileContenido?.replace(".pdf", "");
  const url_PDF = `${process.env.PUBLIC_URL}/PDF/contenido_${folder}/${contenido?.fileContenido}`;

  const [deleteModal, setModalDelete] = useState(false);
  const handleOpenDelete = () => setModalDelete(true);
  const handleCloseDelete = () => {
    setDescargable({
      contenidos_id: id,
    });
    setModalDelete(false);
    setFileName("Seleccionar un archivo");
  };

  const [contentModal, setContentModal] = useState(false);
  const handleOpenDescargable = () => setContentModal(true);
  const handleCloseDescargable = () => {
    setContentModal(false);
    setDescargable({
      contenidos_id: id,
    });
    setFileName("Seleccionar un archivo");
  };

  const getContenido = async () => {
    try {
      const resContenido = await axios.get(
        `${ROUTES_CONTENIDOS.getByID}${id}`,
        configAxios
      );
      setContenido(resContenido.data.data);
      setHasLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const createDescargable = async () => {
    try {
      await axios.post(ROUTES_DESCARGABLES.create, descargable, configAxios);
      handleCloseDescargable();
      getContenido();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDescargable = async () => {
    try {
      await axios.delete(
        `${ROUTES_DESCARGABLES.delete}${descargable.id}`,
        configAxios
      );
      handleCloseDelete();
      getContenido();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContenido();
  }, []);

  return (
    <main className="ContentDownloads">
      <HeaderAdmin
        title="Administración de contenido"
        bgColor="#009DFF"
        text="Vista previa del contenido"
      />
      <section className="ContentDownloads__content">
        {hasLoaded ? (
          <>
            <h1>{contenido.nombreContenido}</h1>
            <div className="ContentDownloads__contentDetail">
              <article className="ContentDownloads__contentDetailPdf">
                {contenido?.formatoContenido === ".pdf" ? (
                  <VisualizadorPDF
                    document={contenido.nombreContenido}
                    url={url_PDF}
                  />
                ) : (
                  <img
                    src={
                      contenido.nombreContenido.includes("cárnicos")
                        ? `${process.env.PUBLIC_URL}/Assets/Carnicos.png`
                        : contenido.nombreContenido.includes("frutas")
                        ? `${process.env.PUBLIC_URL}/Assets/Frutas.png`
                        : contenido.nombreContenido.includes("animales") &&
                          `${process.env.PUBLIC_URL}/Assets/Nutricion.png`
                    }
                    alt=""
                    style={{ width: "100%" }}
                  />
                )}
              </article>
              <section className="ContentDownloads__contentDetailInfo">
                <h3>Descripción de contenido</h3>
                <p>{contenido.descripcionContenido}</p>
                <h3>Archivo de contenido</h3>
                <p>
                  <span>
                    <FontAwesomeIcon icon={faFilePdf} />
                  </span>
                  {contenido.fileContenido}
                </p>
                <div className="ContentDownloads__contentDetailInfoList">
                  <h2>Lista de descargables</h2>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOpenDescargable();
                    }}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCirclePlus} />
                    </span>
                    Agregar descargable
                  </button>
                  <ul>
                    {contenido.descargables.length === 0
                      ? "No hay descargables para este contenido"
                      : contenido.descargables.map((i) => (
                          <li key={i.id}>
                            <p>
                              <span>{i.nombreDescargable}</span>
                              <br />
                              {i.fileDescargable}
                            </p>
                            <button
                              onClick={() => {
                                setDescargable(i);
                                handleOpenDelete();
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </li>
                        ))}
                  </ul>
                </div>
              </section>
            </div>
            <section>
              <ContenidosTable contenidoID={contenido.id} />
            </section>
          </>
        ) : (
          <Loading />
        )}
      </section>
      <ModalDelete
        open={deleteModal}
        handleClose={handleCloseDelete}
        title="Eliminar Descargable"
        name={descargable.nombreDescargable}
        action={deleteDescargable}
        txtAction="Eliminar descargable"
      />

      <ModalDownload
        open={contentModal}
        handleClose={handleCloseDescargable}
        action={createDescargable}
        data={descargable}
        setData={setDescargable}
        fileName={fileName}
        setFileName={setFileName}
        contenido={contenido}
      />
    </main>
  );
};

export default ContentDownloads;
