import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFilterOptions, TablePagination } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  configAxios,
  ROUTES_CONTENIDOS_INSTITUCIONES,
  ROUTES_INSTITUCIONS
} from "../../utils/AxiosAPI";
import { fmtDate, fmtOptionsAutoCompleteSignin } from "../../utils/utils";
import { CssAutocomplete, CssTextField } from "../Search/CssAutocomplete";

import Loading from "../Loading/Loading";
import "./ContenidosTable.scss";

const ContenidosTable = ({ contenidoID }) => {
  const [instituciones, setInstituciones] = useState([]);
  const [contenidosList, setContenidosList] = useState([]);
  const [autocoCompleteValue, setAutoCompleteValue] = useState("");
  const [newData, setNewData] = useState({});
  const [pageLoaded, setPageLoaded] = useState(false);

  const autoCompleteOptions = fmtOptionsAutoCompleteSignin(
    instituciones,
    "nombreInstitucion"
  );

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, 4);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const indexLastData = (page + 1) * rowsPerPage;
  const indexFirstData = indexLastData - rowsPerPage;
  const currentData = instituciones?.slice(indexFirstData, indexLastData);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const blueCell = index =>
    index % 2 !== 0 ? { backgroundColor: "#00ccff20" } : null;

  const getInstituciones = async () => {
    setPageLoaded(false);
    try {
      const res = await axios.get(
        ROUTES_INSTITUCIONS.getAllWhitUsers,
        configAxios
      );
      const data = res?.data?.data?.sort((a, b) =>
        a?.nombreInstitucion.localeCompare(b?.nombreInstitucion)
      );
      setInstituciones(data);
      setPageLoaded(true);
    } catch (err) {
      console.log(err);
    }
  };

  const getContenidosList = async () => {
    setPageLoaded(false);
    try {
      const res = await axios.get(
        `${ROUTES_CONTENIDOS_INSTITUCIONES.byContenido}${contenidoID}`,
        configAxios
      );
      setContenidosList(res?.data?.data);
      setPageLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const addInstitucion = async () => {
    setPageLoaded(false);
    try {
      const res = await axios.post(
        ROUTES_CONTENIDOS_INSTITUCIONES.create,
        newData,
        configAxios
      );
      getContenidosList();
      setPageLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteInstitucion = async id => {
    setPageLoaded(false);
    try {
      await axios.delete(
        `${ROUTES_CONTENIDOS_INSTITUCIONES.delete}${id}`,
        configAxios
      );
      getContenidosList();
      setPageLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getContenidosList();
    getInstituciones();
  }, []);

  if (!pageLoaded) {
    return (
      <div className="contenidosTable">
        <Loading />
      </div>
    );
  }

  // if (contenidosList?.length === 0) {
  //   return (
  //     <div className="contenidosTable">
  //       <h3>Ninguna institucion tiene acceso a este contenido</h3>
  //     </div>
  //   );
  // }

  return (
    <div className="contenidosTable">
      <h3>Acceso a este contenido</h3>
      <div className="contenidosTable__search">
        <h2>Dar acceso:</h2>
        <CssAutocomplete
          id="buscarInstitucion"
          filterOptions={filterOptions}
          options={autoCompleteOptions}
          disableListWrap={true}
          style={{ borderRadius: "5px" }}
          value={autocoCompleteValue}
          onChange={(event, newValue) => {
            setAutoCompleteValue(newValue !== null ? newValue.label : "");
            newValue !== null &&
              setNewData({
                instituciones_clientes_id: newValue.clienteId,
                instituciones_id: newValue.id,
                contenidos_id: contenidoID,
              });
          }}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          renderInput={params => (
            <CssTextField
              {...params}
              placeholder={"Buscar una institución"}
              sx={{ fontSize: "12px" }}
            />
          )}
          size="small"
        />
        <button
          disabled={autocoCompleteValue === ""}
          onClick={e => {
            e.preventDefault();
            addInstitucion();
          }}
        >
          Dar acceso
        </button>
      </div>
      <table className="contenidosTable__table">
        <thead>
          <tr>
            <th>Institucion</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentData?.map((row, index) =>
            contenidosList?.map(
              contenido =>
                contenido.instituciones_id === row.id && (
                  <tr key={row.id}>
                    <th style={{ backgroundColor: "#00ccff10" }}>
                      {row.nombreInstitucion}
                    </th>
                    <th style={{ backgroundColor: "#00ccff10" }}>
                      <button
                        onClick={e => {
                          e.preventDefault();
                          deleteInstitucion(contenido.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </th>
                  </tr>
                )
            )
          )}
        </tbody>
      </table>
      <TablePagination
        component="div"
        count={instituciones?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ color: "#0A8FEC" }}
        labelRowsPerPage={"Filas por pagina"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
        }
      />
    </div>
  );
};

export default ContenidosTable;
