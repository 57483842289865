import {
    faArrowRightFromBracket,
    faBookmark,
    faFileCircleCheck,
    faGear,
    faHouse,
    faTableColumns,
    faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    configAxios,
    ROUTES_CONTENIDOS_INSTITUCIONES,
    ROUTES_INSTITUCIONS,
} from "../../utils/AxiosAPI";
import { randomColor } from "../../utils/utils";
import "./AppNavigation.scss";
import { useLocation } from "react-router-dom";

function AppNavigation({ userData = null }) {
    const { apellidoUsuario, emailUsuario, instituciones_id, nombreUsuario, tipoUsuario, id } =
        userData[0];

    const location = useLocation();

    const [institucion, setInstitucion] = useState("");
    const [contenidosTotales, setContenidosTotales] = useState(0);

    const killSession = () => {
        localStorage.clear();
    };

    const getInstitucion = async () => {
        try {
            const res = await axios.get(
                `${ROUTES_INSTITUCIONS.getByID}${instituciones_id}`,
                configAxios,
            );
            setInstitucion(res?.data?.data?.nombreInstitucion);
        } catch (error) {
            console.log(error);
        }
    };

    const getContenidos = async () => {
        try {
            const res = await axios.get(
                `${ROUTES_CONTENIDOS_INSTITUCIONES.byInstitucion}${instituciones_id}`,
                configAxios,
            );
            setContenidosTotales(res?.data?.data.length);
        } catch (error) {
            console.log(error);
        }
    };

    const colorBG = useCallback(randomColor(), []);

    useEffect(() => {
        getInstitucion();
        getContenidos();
    }, []);

    return (
        <nav className="navigation">
            <div className="navigation__user">
                <div className="navigation__userName">
                    <Link to="/">
                        <div style={{ backgroundColor: colorBG }}>
                            {nombreUsuario[0]}
                            {apellidoUsuario[0]}
                            <FontAwesomeIcon
                                icon={faHouse}
                                className="HomeIcon"
                            />
                        </div>
                    </Link>
                </div>
                <h5>{nombreUsuario}</h5>
                <h4>{apellidoUsuario}</h4>
                <p>{emailUsuario}</p>
                <h3>{institucion}</h3>
            </div>

            <div className="navigation__contents">
                <h3>
                    <FontAwesomeIcon icon={faBookmark} /> Tus contenidos
                </h3>

                <div>
                    {contenidosTotales}
                    <p>
                        <FontAwesomeIcon icon={faFileCircleCheck} />
                        <br />
                        contenidos
                        <br />
                        activos
                    </p>
                </div>
            </div>

            <div className="navigation__buttons">
                {tipoUsuario === 1 && (
                    <Link to="/tknovaRoot">
                        <FontAwesomeIcon
                            icon={faTableColumns}
                            className="navigation__buttonsIcon"
                        />
                        Administrar
                    </Link>
                )}
                <Link to={`/recordpass/${id}`}>
                    <FontAwesomeIcon
                        icon={faGear}
                        className="navigation__buttonsIcon"
                    />
                    Cambiar contraseña
                </Link>
                <Link to="mailto:tknova-academy@grupotknova.com?subject=Soporte%20Tknova%20Academy">
                    <FontAwesomeIcon
                        icon={faEnvelopeOpen}
                        className="navigation__buttonsIcon"
                    />
                    Concactar a soporte
                </Link>
                <Link
                    to="/login"
                    onClick={killSession}>
                    <FontAwesomeIcon
                        icon={faArrowRightFromBracket}
                        className="navigation__buttonsIcon"
                    />
                    Cerrar sesión
                </Link>
                <span className="navigation__version">Versión 1.0.0</span>
            </div>
        </nav>
    );
}

export default AppNavigation;
