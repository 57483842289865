import React from "react";
import logoTkanova from "../../assets/img/LogoIMG.png";
import "./Logo.scss";

function LogoUpLeft({ forPage }) {
  return (
    <picture className={forPage}>
      <img src={logoTkanova} alt="tknova-logo" />
    </picture>
  );
}

export default LogoUpLeft;
