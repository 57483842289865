import "./CodeForm.scss";
import axios from "axios";
import React, { useState } from "react";
import { addExtraDays } from "../../utils/utils";
import { configAxios, ROUTES_CODIGOS } from "../../utils/AxiosAPI";

const CodeForm = ({
  institucionData,
  getCodes,
  setCodesNewList,
  setMailDisabled,
  setFechaCaducidad
}) => {
  const inputDisable = institucionData === null;
  const [newCodeDate, setNewCodeDate] = useState("");
  const [codesAmount, setCodesAmount] = useState(1);
  const [extra, setExtra] = useState(0);
  const creatCode = async () => {
    setMailDisabled(true);
    setCodesNewList([])
    try {
      const res = await axios.post(
        ROUTES_CODIGOS.create,
        {
          vigencia: newCodeDate,
          instituciones_id: institucionData.id,
          instituciones_clientes_id: institucionData.clientes_id,
          diasExtra: extra,
        },
        configAxios
      );
      setCodesNewList(current => [...current, res?.data?.data?.codigo]);
      setFechaCaducidad(res?.data?.data?.vigencia)
      setMailDisabled(false);
      setCodesAmount(0);
    } catch (error) {
      console.log(error);
    }
  };

  const generator = () => {
    for (let index = 0; index < codesAmount; index++) {
      creatCode();
    }
  };

  return (
    <form className="CodeForm">
      <input
        type="text"
        name="amount"
        min={1}
        placeholder="0000"
        autoComplete="off"
        disabled={inputDisable}
        onChange={e => {
          setCodesAmount(parseInt(e.target.value));
        }}
      />
      <p>Cantidad de codigos a generar.</p>
      <div className="CodeForm__date">
        <input
          type="date"
          name="day"
          autoComplete="off"
          min={1}
          max={31}
          disabled={inputDisable}
          onChange={e => {
            setNewCodeDate(e.target.value);
          }}
        />
      </div>
      <p>Fecha de caducidad de codigos.</p>
      <div className="CodeForm__days">
        <input
          type="number"
          name="amount"
          autoComplete="off"
          min={1}
          placeholder="dias de gracia"
          disabled={inputDisable}
          onChange={e => {
            setExtra(e.target.value);
          }}
        />
        <button
          disabled={Object.keys(newCodeDate).length === 0}
          onClick={e => {
            e.preventDefault();
            generator();
            getCodes();
          }}
        >
          GENERAR
        </button>
      </div>
    </form>
  );
};

export default CodeForm;
