import './HeaderAdmin.scss'
import React from 'react'
import headerImg from '../../assets/img/Shiny_Happy_Outdoors.png'

const HeaderAdmin = ({title, text = false, bgColor}) => {
  return (
    <header className='Adminheader' style={{backgroundColor: bgColor}}>
        <h1>{title}</h1>
        {text && <h6>{text}</h6>}
        <figure>
            <img src={headerImg} alt="headerImg" />
        </figure>
    </header>
  )
}

export default HeaderAdmin