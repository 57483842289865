import "./Search.scss";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faSearch, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { CssAutocomplete, CssTextField } from "./CssAutocomplete";
import { createFilterOptions } from "@mui/material";

const Search = ({
  label,
  options,
  buttonTxt = false,
  action,
  setDateStart = false,
  setDateEnd = false,
  autocoCompleteValue,
  setAutoCompleteValue,
}) => {
  const [dateStartValue, setDateStartValue] = useState("");
  const [dateEndValue, setDateEndValue] = useState("");

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, 4);
  };
  return (
    <section className="search">
      <div className="search__filters">
        <div className="search__filters__input">
          <div className="search__filters__icon">
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <CssAutocomplete
            filterOptions={filterOptions}
            id="search"
            options={options}
            disableListWrap={true}
            value={autocoCompleteValue}
            isOptionEqualToValue={(option, value) =>
              option.title === value.title
            }
            onChange={(event, newValue) => {
              setAutoCompleteValue(newValue !== null ? newValue.label : "");
            }}
            renderInput={params => (
              <CssTextField
                {...params}
                placeholder={label}
                sx={{ fontSize: "12px" }}
              />
            )}
            size="small"
          />
        </div>
        {(setDateEnd || setDateStart) && (
          <div className="search__filters__date">
            <label htmlFor="dateStart">
              Inicio:
              <input
                type="month"
                name="date"
                id="dateStart"
                max={dateEndValue}
                value={dateStartValue}
                onChange={e => {
                  setDateStartValue(e.target.value);
                  setDateStart(e.target.value);
                }}
              />
            </label>
            <label htmlFor="dateEnd">
              Final:
              <input
                type="month"
                name="date"
                id="dateEnd"
                value={dateEndValue}
                min={dateStartValue}
                onChange={e => {
                  setDateEndValue(e.target.value);
                  setDateEnd(e.target.value);
                }}
              />
            </label>
            {(dateStartValue !== "" || dateEndValue !== "") && (
              <FontAwesomeIcon
                className="search__filters__dateIcon"
                icon={faTrash}
                onClick={() => {
                  setDateStartValue("");
                  setDateStart("");
                  setDateEndValue("");
                  setDateEnd("");
                }}
              />
            )}
          </div>
        )}
      </div>
      {buttonTxt && (
        <button
          className="search__button"
          onClick={e => {
            e.preventDefault();
            action();
          }}
        >
          <FontAwesomeIcon icon={faCirclePlus} /> {buttonTxt}
        </button>
      )}
    </section>
  );
};

export default Search;
