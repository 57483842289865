import "./AdminClients.scss";
import React, { useCallback, useEffect, useState } from "react";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import Search from "../../components/Search/Search";
import TableAdmin from "../../components/TableAdmin/TableAdmin";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import ModalClients from "../../components/ModalClients/ModalClients";
import { fmtMonth, fmtOptionsAutoComplete } from "../../utils/utils";
import { configAxios, ROUTES_INSTITUCIONS, RUOTES_CLIENTS } from "../../utils/AxiosAPI";
import axios from "axios";
import Loading from "../../components/Loading/Loading";

const AdminClients = () => {
  const thead = [
    "Nombre",
    "Ciudad",
    "Departamento",
    "Fecha inicio",
    "Fecha final",
    "No. de instituciones",
  ];

  const [rowsClients, setRowsClients] = useState([]);
  const [FilteredData, setFilteredData] = useState([]);

  const [haspageLoaded, setHasPageLoaded] = useState(false);

  const [clientData, setClientData] = useState({});
  const [dataToDeletEdit, setDataToDeletEdit] = useState({});
  const [asInstitucion, setAsInstitucion] = useState(false);

  const [deleteModal, setModalDelete] = useState(false);
  const handleOpenDelete = clientData => {
    setModalDelete(true);
    setDataToDeletEdit(clientData);
  };
  const handleCloseDelete = () => setModalDelete(false);

  const [clientModal, setClientModal] = useState(false);
  const handleOpenClient = () => setClientModal(true);
  const handleCloseClient = () => {
    setClientModal(false);
    setClientData({})
  }

  const [clientModalEdit, setClientDeleteEdit] = useState(false);
  const handleOpenClientEdit = clientData => {
    setClientDeleteEdit(true);
    setDataToDeletEdit(clientData);
  };
  const handleCloseClientEdit = () => {
    setClientDeleteEdit(false)
    setClientData({})
  };

  const [dateStartFilter, setDateStartFilter] = useState("");
  const [dateEndFilter, setDateEndFilter] = useState("");

  const autoCompleteOptions = fmtOptionsAutoComplete(
    rowsClients,
    "nombreCliente"
  );
  const [autocoCompleteValue, setAutoCompleteValue] = useState("");

  const getData = async () => {
    try {
      const res = await axios.get(
        RUOTES_CLIENTS.getAllInstituciones,
        configAxios
      );
      const data = res.data.data.sort((a, b) =>
        a.nombreCliente.localeCompare(b.nombreCliente)
      );
      setRowsClients(data);
      setFilteredData(data);
      setHasPageLoaded(true);
    } catch (err) {
      console.log(err);
    }
  };

  const createClient = useCallback(async () => {
    try {
      const res = await axios.post(
        RUOTES_CLIENTS.createClient,
        clientData,
        configAxios
      );
      if (asInstitucion) {
        await axios.post(
          ROUTES_INSTITUCIONS.createInstitucion,
          {
            nombreInstitucion: clientData.nombreCliente,
            ciudadInstitucion: clientData.ciudadCliente,
            departamentoInstitucion: clientData.departamentoCliente,
            clientes_id: res.data.data.id,
          },
          configAxios
        );
      }
      handleCloseClient();
      getData();
    } catch (err) {
      console.log(err);
    }
  },[clientData, asInstitucion]);

  const handlerDelete = useCallback(async () => {
    try {
      const res = await axios.delete(
        `${RUOTES_CLIENTS.editDelete}${dataToDeletEdit.id}`,
        configAxios
      );
      if (res.status > 199 || res.status < 300) {
        handleCloseDelete();
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }, [dataToDeletEdit.id]);

  const handlerEdit = useCallback(async () => {
    try {
      const res = await axios.put(
        `${RUOTES_CLIENTS.editDelete}${dataToDeletEdit.id}`,
        clientData,
        configAxios
      );
      if (res.status > 199 || res.status < 300) {
        handleCloseClientEdit();
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  }, [dataToDeletEdit.id, clientData]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const filterdByDate = rowsClients
      .filter(row => {
        return dateStartFilter === ""
          ? row
          : fmtMonth(row.inicio) === dateStartFilter;
      })
      .filter(row => {
        return dateEndFilter === ""
          ? row
          : fmtMonth(row.final) === dateEndFilter;
      })
      .filter(row => {
        return autocoCompleteValue === ""
          ? row
          : row.nombreCliente === autocoCompleteValue;
      });
    setFilteredData(filterdByDate);
  }, [dateStartFilter, dateEndFilter, autocoCompleteValue, rowsClients]);

  return (
    <main className="adminClients">
      <HeaderAdmin
        title="Administración de clientes"
        text="Lista de clientes inscritos"
        bgColor="#00CCFF"
      />
      <section className="adminClients__content">
        {haspageLoaded ? (
          <>
            <Search
              label="Buscar un cliente"
              options={autoCompleteOptions}
              buttonTxt="Agregar nuevo cliente"
              action={handleOpenClient}
              setDateStart={setDateStartFilter}
              setDateEnd={setDateEndFilter}
              autocoCompleteValue={autocoCompleteValue}
              setAutoCompleteValue={setAutoCompleteValue}
            />
            <TableAdmin
              colums={thead}
              rows={FilteredData}
              editOpt={handleOpenClientEdit}
              deleteOpt={handleOpenDelete}
              total="instituciones"
            />
            <ModalDelete
              open={deleteModal}
              handleClose={handleCloseDelete}
              title="Eliminar cliente"
              name={dataToDeletEdit.nombreCliente}
              action={handlerDelete}
              txtAction="Eliminar cliente"
            />

            <ModalClients
              open={clientModal}
              handleClose={handleCloseClient}
              action={createClient}
              setClientData={setClientData}
              clientData={clientData}
              asInstitucion={asInstitucion}
              setAsInstitucion={setAsInstitucion}
            />

            <ModalClients
              open={clientModalEdit}
              handleClose={handleCloseClientEdit}
              action={handlerEdit}
              setClientData={setClientData}
              clientData={dataToDeletEdit}
              edit
            />
          </>
        ) : (
          <Loading />
        )}
      </section>
    </main>
  );
};

export default AdminClients;
