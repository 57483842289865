export const fmtDate = date => {
    const newDate = new Date(date);
    const day = newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
    const month =
        newDate.getMonth() + 1 < 10 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    return `${day}-${month}-${year}`;
};

export const fmtMonth = date => {
    const newDate = new Date(date);
    const month =
        newDate.getMonth() + 1 < 10 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    return `${year}-${month}`;
};

export const fmtOptionsAutoComplete = (arr, key) => {
    const newList = [];
    arr?.forEach(i => {
        newList.push({
            id: i.id,
            label: i[key],
        });
    });

    return newList;
};

export const fmtOptionsAutoCompleteSignin = arr => {
    const newList = [];
    arr?.forEach(i => {
        newList.push({
            id: i.id,
            label: i["nombreInstitucion"],
            clienteId: i["clientes_id"],
        });
    });

    return newList;
};

export const fmtDeparments = arr => {
    const newList = [];
    arr?.forEach(i => {
        newList.push(i.departamento);
    });

    return newList;
};

export const fmtCities = (arr, dep) => {
    const newList = [];
    arr?.forEach(i => {
        if (i.departamento === dep) {
            i.ciudades.map(j => newList.push(j));
        }
    });

    return newList;
};

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const addExtraDays = (fecha, extra) => {
    const date = fecha?.split("-"),
        hoy = new Date(date[0], date[1], date[2]),
        dias = parseInt(extra),
        calculado = new Date(),
        dateResul = hoy.getDate() + dias;
    calculado.setDate(dateResul);
    return fmtDate(calculado);
};

export const fmtCodeList = arr => {
    let list = "";
    arr?.forEach((i, index) => (list += `Código No.${index + 1}: ${i}\n`));
    return list;
};

export const fmtCodeList2 = arr => {
    let list = "";
    arr?.forEach((i, index) => (list += `Código No.${index + 1}: ${i}<br>`));
    return list;
};

export const randomColor = () => {
    const colors = [
        "#FFB627",
        "#0A8FEC",
        "#00009C",
        "#010194",
        "#5800FF",
        "#009dff",
        "#00CCFF",
        "#5800FF",
    ];

    const randomNumber = Math.floor(Math.random() * 8);

    return colors[randomNumber];
};

export const dateVerification = date => {
    const today = new Date();
    const expiration = new Date(date);
    const fecha = fmtDate(expiration);
    return fecha > today;
};
