import React from "react";
import loginImg from "../../assets/img/loginImg.png";
import "./BannerWelcome.scss";

function BannerWelcome({ isSignIn = false }) {
  return (
    <section className="bannerWelcome">
      <h2>¡Bienvenido a tu<br/>plataforma de contenidos!</h2>
      {isSignIn && (
        <p>
          Este es el perfil que será usado para identificarte<br/>en la plataforma.
        </p>
      )}
      <picture>
        <img src={loginImg} alt="welcome" />
      </picture>
    </section>
  );
}

export default BannerWelcome;
