import { Route, Routes } from "react-router-dom";
import "./App.scss";
import AdminLayoud from "./layoud/AdminLayoud/AdminLayoud";
import UserLayoud from "./layoud/UserLayoud/UserLayoud";
import AdminClients from "./pages/AdminClients/AdminClients";
import AdminContent from "./pages/AdminContent/AdminContent";
import AdminInstitucion from "./pages/AdminInstitucion/AdminInstitucion";
import { AdminSumary } from "./pages/AdminSumary/AdminSumary";
import AdminUsers from "./pages/AdminUsers/AdminUsers";
import CodesGenerator from "./pages/CodesGenerator/CodesGenerator";
import Content from "./pages/Content/Content";
import ContentDownloads from "./pages/ContentDownloads/ContentDownloads";
import Home from "./pages/Home/Home";
import LogIn from "./pages/LogIn/LogIn";
import RecordarPass from "./pages/RecordarPass/RecordarPass";
import SignIn from "./pages/SignIn/SignIn";
import Error404 from "./pages/Error404/Error404";
import IframeContainer from "./components/IframeContainer/IframeContainer";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route
                    path="/"
                    element={<UserLayoud />}>
                    <Route
                        path="/"
                        element={<Home />}
                    />
                    <Route
                        path="/content/:id"
                        element={<Content />}
                    />
                </Route>
                <Route
                    path="/tknovaRoot"
                    element={<AdminLayoud />}>
                    <Route
                        path="/tknovaRoot"
                        element={<AdminSumary />}
                    />
                    <Route
                        path="/tknovaRoot/clientes"
                        element={<AdminClients />}
                    />
                    <Route
                        path="/tknovaRoot/Instituciones"
                        element={<AdminInstitucion />}
                    />
                    <Route
                        path="/tknovaRoot/contenido"
                        element={<AdminContent />}
                    />
                    <Route
                        path="/tknovaRoot/contenido/:id"
                        element={<ContentDownloads />}
                    />
                    <Route
                        path="/tknovaRoot/Usuarios"
                        element={<AdminUsers />}
                    />
                    <Route
                        path="/tknovaRoot/Generador"
                        element={<CodesGenerator />}
                    />
                </Route>

                {/* AgroIndustriaProcesosCarnicos */}
                <Route
                    path="/content-ai/489d316d-9485-44a0-a740-69fc9fd35d93"
                    element={
                        <IframeContainer
                            button
                            src={"/Agroindustria/Carnicos/index.html"}
                            id={"489d316d-9485-44a0-a740-69fc9fd35d93"}
                        />
                    }
                />
                {/* AgroIndustriaProcesoFruver */}
                <Route
                    path="/content-ai/278d316d-9485-44a0-a740-69fc9fd3qwxa"
                    element={
                        <IframeContainer
                            button
                            src={"/Agroindustria/Fruver/index.html"}
                            id={"278d316d-9485-44a0-a740-69fc9fd3qwxa"}
                        />
                    }
                />
                {/* AgroIndustriaProcesoNutricion */}
                <Route
                    path="/content-ai/68shk16d-9485-44a0-a740-69fc9fd0sdil"
                    element={
                        <IframeContainer
                            button
                            src={"/Agroindustria/Nutricion/index.html"}
                            id={"68shk16d-9485-44a0-a740-69fc9fd0sdil"}
                        />
                    }
                />
                {/* TEST Concentrado animales  */}
                <Route
                    path="/content-ai/cd508bfd-dc15-4f5b-9b66-1007170ed5c4"
                    element={
                        <IframeContainer
                            button
                            src={"https://view.genially.com/675309a01e1be6adbfdb4d4f"}
                            id={"cd508bfd-dc15-4f5b-9b66-1007170ed5c4"}
                        />
                    }
                />

                <Route
                    path="/login"
                    element={<LogIn />}
                />
                <Route
                    path="/signin"
                    element={<SignIn />}
                />
                <Route
                    path="/recordpass/:id"
                    element={<RecordarPass />}
                />
                <Route
                    path="*"
                    element={<Error404 />}
                />
            </Routes>
        </div>
    );
}

export default App;
