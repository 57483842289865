import {
    faCaretDown,
    faCaretRight,
    faComments,
    faFilePdf,
    faSquarePen,
    faDiceD6,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Collapse from "@mui/material/Collapse";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Download from "../../components/Download/Download";
import VisualizadorPDF from "../../components/VisualizadorPDF/VisualizadorPDF";
import { configAxios, ROUTES_INSTITUCIONS } from "../../utils/AxiosAPI";
import "./Content.scss";
import Commits from "../../components/Commits/Commits";
import { TextField, Alert } from "@mui/material";
import { dateVerification, delay } from "../../utils/utils";
import IframeContainer2 from "../../components/IframeContainerContent/IframeContaine2";

function Content() {
    const userLocalStorage = localStorage.getItem("usuario")
        ? JSON.parse(localStorage.getItem("usuario"))
        : null;

    const [extend, setExtend] = useState(false);
    const [more, setMore] = useState(false);
    const [fileData, setFileData] = useState();
    const contentId = useParams();
    const [createOk, setCreateOk] = useState(false);
    const [commitData, setCommitData] = useState({
        comentario: "",
        asuntoComentario: "",
        usuarios_id: "",
        usuarios_clientes_id: "",
        contenidos_id: "",
    });
    const [comentario, setComentario] = useState("");
    const [asunto, setAsunto] = useState("");
    const [commitAll, setCommitAll] = useState([]);
    const [user, setUser] = useState();
    const folder = fileData?.fileContenido?.replace(".pdf", "");
    const url_PDF = `${process.env.PUBLIC_URL}/PDF/contenido_${folder}/${fileData?.fileContenido}`;
    const [maxi, setMaxi] = useState(false);
    const [institucion, setInstitucion] = useState({});
    const [hasContendio, setHasContenido] = useState(false);

    const isExpiratedDate = dateVerification(institucion?.final);

    const navigate = useNavigate();

    const consultarContenidoPDF = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_URL_BACKEND}contenidos/${contentId?.id}`,
                configAxios,
            );
            setFileData(res?.data?.data);
        } catch (err) {
            console.log(err);
        }
    };

    const consultarComentarios = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_URL_BACKEND}comentarios/content/${contentId.id}`,
                configAxios,
            );
            setCommitAll(res?.data?.data);
        } catch (err) {
            console.log(err);
        }
    };

    const consultarUsuarios = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}users/`, configAxios);
            setUser(res?.data?.data);
        } catch (err) {
            console.log(err);
        }
    };

    const ultimoContenido = async () => {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_URL_BACKEND}users/last/${userLocalStorage[0].id}`,
                { ultimoContenido: `${contentId?.id}` },
                configAxios,
            );
        } catch (err) {
            console.log(err);
        }
    };

    const handleOk = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_URL_BACKEND}comentarios/`,
                {
                    comentario: `${comentario}`,
                    asuntoComentario: `${asunto}`,
                    usuarios_id: userLocalStorage[0].id,
                    usuarios_clientes_id: userLocalStorage[0].instituciones_clientes_id,
                    contenidos_id: contentId?.id,
                },
                configAxios,
            );
            if (response?.data?.status === "ok") {
                setCommitData({
                    comentario: "",
                    asuntoComentario: "",
                    usuarios_id: "",
                    usuarios_clientes_id: "",
                    contenidos_id: "",
                });
                setAsunto("");
                setComentario("");
                setCreateOk(true);
                consultarComentarios();
                await delay(4000);
                setCreateOk(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getInstitucion = async () => {
        try {
            const res = await axios.get(
                `${ROUTES_INSTITUCIONS.getByID}${userLocalStorage[0].instituciones_id}`,
                configAxios,
            );
            setInstitucion(res?.data?.data);
            res?.data?.data?.contenidos?.forEach(
                i => i.id === contentId?.id && setHasContenido(true),
            );
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        consultarContenidoPDF();
        consultarComentarios();
        consultarUsuarios();
        ultimoContenido();
        setMaxi(false);
        getInstitucion();

        if (isExpiratedDate) {
            navigate("/");
        }
    }, []);

    return (
        <>
            <header>
                <section className="ContentName">
                    <div className="IconDiv">
                        <FontAwesomeIcon
                            className="Icon"
                            icon={fileData?.formatoContenido === ".pdf" ? faFilePdf : faDiceD6}
                        />
                        <h2>{fileData?.nombreContenido}</h2>
                    </div>
                    <section className="Descripcion">{fileData?.descripcionContenido}</section>
                </section>
            </header>

            {fileData?.formatoContenido === ".pdf" ? (
                <section className="PDF-viewer">
                    <VisualizadorPDF
                        document={fileData?.nombreContenido}
                        url={url_PDF}
                    />
                </section>
            ) : (
                <section className="Iframe-viewer">
                    <IframeContainer2 src={fileData?.fileContenido} />
                </section>
            )}

            {fileData?.formatoContenido === "angular" && (
                <div className="contentMaxi">
                    <Link
                        to={"/content-ai/" + fileData?.id}
                        className="maxiLink">
                        Maximizar Contenido
                    </Link>
                </div>
            )}

            <section className="ContentDescargable">
                <div className="IconDiv">
                    <FontAwesomeIcon
                        className="Icon"
                        icon={faSquarePen}
                    />
                    <h2>Contenido descargable</h2>
                </div>
                <section className="DownloadCard">
                    {fileData?.descargables?.length === 0
                        ? "No hay descargables para este contenido"
                        : fileData?.descargables
                              ?.slice() // Crear una copia del array para no modificar el original
                              .filter(i => i.nombreDescargable.match(/\d+/))
                              .sort((a, b) => {
                                  const numA = parseInt(a.nombreDescargable.match(/\d+/)[0]);
                                  const numB = parseInt(b.nombreDescargable.match(/\d+/)[0]);
                                  return numA - numB;
                              })
                              .map(i => {
                                  return (
                                      <React.Fragment key={i.id}>
                                          <Download
                                              nombre={i.nombreDescargable}
                                              documento={`${process.env.PUBLIC_URL}/PDF/contenido_${folder}/${i.fileDescargable}`}
                                          />
                                      </React.Fragment>
                                  );
                              })}
                </section>
            </section>
            <section className="ContentComments">
                <div
                    className="IconDiv"
                    onClick={e => {
                        setExtend(!extend);
                        setMore(false);
                    }}>
                    <FontAwesomeIcon
                        className="Icon"
                        icon={faSquarePen}
                    />
                    <h2>Comentarios del contenido</h2>
                    <FontAwesomeIcon
                        className="IconArrow"
                        icon={extend ? faCaretDown : faCaretRight}
                    />
                </div>
                <section className="Commit_1">
                    <Collapse
                        in={extend}
                        timeout="auto"
                        unmountOnExit
                        sx={{ width: 900 }}>
                        {more
                            ? commitAll?.map(i => {
                                  return (
                                      <Commits
                                          key={i.id}
                                          comment={i}
                                          usuario={i.usuarios}
                                      />
                                  );
                              })
                            : commitAll?.slice(0, 2).map(i => {
                                  return (
                                      <Commits
                                          key={i.id}
                                          comment={i}
                                          usuario={i.usuarios}
                                      />
                                  );
                              })}

                        <Collapse
                            in={more}
                            timeout="auto"
                            unmountOnExit
                            sx={{ width: 900 }}></Collapse>
                        <section
                            className="secctionMore"
                            onClick={() => {
                                setMore(!more);
                            }}>
                            {commitAll?.length > 2 ? (
                                <>
                                    <FontAwesomeIcon
                                        className="IconMore"
                                        icon={faComments}
                                    />
                                    <p>{more ? "Ocultar" : "Ver"} comentarios más antiguos</p>
                                </>
                            ) : (
                                <p>No hay comentarios anteriores</p>
                            )}
                        </section>

                        <form className="ComitForm">
                            <h4>Añade un nuevo comentario</h4>
                            <TextField
                                sx={{ marginTop: "15px" }}
                                placeholder="Asunto"
                                id="AsuntoCommit"
                                label="Asunto"
                                variant="outlined"
                                value={asunto}
                                onChange={e => {
                                    setAsunto(e.target.value);
                                }}
                            />
                            <TextField
                                sx={{ marginTop: "15px" }}
                                id="Commit"
                                label="Comentario"
                                placeholder="Deja acá tu comentario. Recomendamos usar menos de 300 caracteres."
                                multiline
                                rows={4}
                                value={comentario}
                                onChange={e => {
                                    setComentario(e.target.value);
                                }}
                            />
                            <button
                                onClick={e => {
                                    e.preventDefault();
                                    handleOk();
                                }}>
                                Enviar
                            </button>
                            {createOk && <Alert>Comentario creado correctamente</Alert>}
                        </form>
                    </Collapse>
                </section>
            </section>
        </>
    );
}

export default Content;
