import { TextField } from "@mui/material";

import { styled } from "@mui/material/styles";

export const CssTextField = styled(TextField)({
    width: "100%",
    color: "#fff",
    "& label": {
        color: "#fff",
        fontSize: "20px",
    },
    "& label.Mui-focused": {
        color: "#fff",
    },
    "& .MuiInput-underline:after": {
        border: "none",
        color: "#fff",
    },
    "& .MuiOutlinedInput-root": {
        color: "#fff",
        fontSize: "20px",
        "& fieldset": {
            border: "none",
        },
        "&:hover fieldset": {
            borderColor: "none",
            color: "#fff",
        },
        "&.Mui-focused fieldset": {
            borderColor: "none",
            color: "#fff",
        },
    },
});

export const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #009dff inset" };
