import "./TableAdmin.scss";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faEye,
  faEyeSlash,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { TablePagination } from "@mui/material";
import { Link } from "react-router-dom";

const TableAdmin = ({
  colums,
  rows,
  editOpt = false,
  deleteOpt = false,
  activeOpt = false,
  detail = false,
  total = false,
}) => {
  //pagination config
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const indexLastData = (page + 1) * rowsPerPage;
  const indexFirstData = indexLastData - rowsPerPage;
  const currentData = rows.slice(indexFirstData, indexLastData);

  const blueCell = index =>
    index % 2 !== 0 ? { backgroundColor: "#00ccff20" } : null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <section>
      <table className="tableAdmin">
        <thead>
          <tr>
            {colums.map((row, index) => (
              <th key={index}>{row}</th>
            ))}
            {(editOpt || deleteOpt || activeOpt) && (
              <th id="actions">Acciones</th>
            )}
          </tr>
        </thead>
        <tbody>
          {currentData.map((row, index) => (
            <tr key={row.id}>
              {Object.entries(row).map(
                ([key, value], j) =>
                  key !== "id" &&
                  !key.includes("_id") &&
                  key !== "created_at" &&
                  key !== "updated_at" &&
                  key !== "deleted_at" &&
                  key !== "activo" &&
                  key !== "fileContenido" &&
                  key !== "descripcionContenido" &&
                  key !== "imgContenido"&&
                  key !== total && (
                    <th key={j} style={blueCell(index)}>
                      {value}
                    </th>
                  )
              )}
              {total &&
                Object.entries(row).map(
                  ([key, value], j) =>
                    key === total && (
                      <th key={j} style={blueCell(index)}>
                        {value.length}
                      </th>
                    )
                )}
              {(editOpt || deleteOpt || activeOpt) && (
                <th id="actionsBody" style={blueCell(index)}>
                  {activeOpt && (
                    <div id="actionsBodyActive" onClick={() => activeOpt(row.id, row.activo)}>
                      {row.activo === 1 ? (
                        <FontAwesomeIcon icon={faEye} />
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} style={{color: "#c4c4c4"}} />
                      )}
                    </div>
                  )}
                  {editOpt && (
                    <div id="actionsBodyEdit" onClick={() => editOpt(row)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                  )}
                  {deleteOpt && (
                    <div id="actionsBodyDelete" onClick={() => deleteOpt(row)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  )}
                  {detail && (
                    <div id="actionsBodyLink">
                      <Link to={`/tknovaRoot/contenido/${row.id}`}>
                        <FontAwesomeIcon icon={faRightFromBracket} />
                      </Link>
                    </div>
                  )}
                </th>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ color: "#0A8FEC" }}
        labelRowsPerPage={"Filas por pagina"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
        }
      />
    </section>
  );
};

export default TableAdmin;
