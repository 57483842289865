import ViewSDKClient from "../../utils/ViewSDKClient";

function VisualizadorPDF({document, url}) {
  const viewSDKClient = new ViewSDKClient();
  viewSDKClient.ready().then(() => {
    /* Invoke file preview */
    viewSDKClient.previewFile(
      "pdf-div",
      {
        /* Pass the embed mode option here */
        embedMode: "SIZED_CONTAINER",
        /* If true, a download button appears in the overflow menu on the top bar. */
        showDownloadPDF: false,
        /* If true, then a print PDF option appears in the overflow menu on the top bar. */
        showPrintPDF: false,
        showLeftHandPanel: false,
        showAnnotationTools: false
      },
      `${document}`,
      `${url}`
    );
  });

  return <div id="pdf-div" className="sized-container-div"></div>;
}

export default VisualizadorPDF;
