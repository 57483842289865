import React from "react";
import { Link } from "react-router-dom";
import "./Error404.scss";

const Error404 = () => {
  return (
    <div className="error">
      <h1>Error 404</h1>
      <h4>Pagina no encontrada</h4>
      <Link to="/">Regresar</Link>
    </div>
  );
};

export default Error404;
