import "./AdminLayoud.scss";
import React, { useEffect } from "react";
import AdminNavigation from "../../components/AdminNavigation/AdminNavigation";
import Footer from "../../components/Footer/Footer";
import Logo from "../../components/Logo/Logo";
import { Outlet, useNavigate } from "react-router-dom";

const AdminLayoud = () => {
  const userLocalStorage = localStorage.getItem("usuario")
    ? JSON.parse(localStorage.getItem("usuario"))
    : null;

  const navigate = useNavigate();

  const userVerification = userLocalStorage === null;

  useEffect(() => {
    if (userVerification) {
      navigate("/login");
    }
  }, []);

  if (!userVerification && userLocalStorage.tipoUsuario !== 1) {
    return (
      <section className="adminLayoud">
        <Logo forPage="admin" />
        <AdminNavigation userData={userLocalStorage} />
        <main className="adminLayoud__Content">
          <Outlet />
        </main>
      </section>
    );
  }
};

export default AdminLayoud;
