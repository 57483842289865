import { faFilePdf, faDiceD6 } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import "./ContentCard.scss";

function ContentCard({ link, content }) {
  return (
    <>
      <Link to={link} className="ContentCard">
        <article>
          <div
            className={
              content?.formatoContenido === ".pdf" ? "Content" : "ContentBlue"
            }
          >
            <FontAwesomeIcon
              className="Icon"
              icon={content?.formatoContenido === ".pdf" ? faFilePdf : faDiceD6}
            />
            <img
              className="ImageContainer"
              src={
                content?.nombreContenido.includes("cárnicos")
                  ? `${process.env.PUBLIC_URL}/Assets/Carnicos.png`
                  : content?.nombreContenido.includes("frutas")
                  ? `${process.env.PUBLIC_URL}/Assets/Frutas.png`
                  : content?.nombreContenido.includes("alimento")
                  ? `${process.env.PUBLIC_URL}/Assets/Nutricion.png`
                  : `${
                      process.env.PUBLIC_URL
                    }/PDF/contenido_${content?.fileContenido.replace(
                      ".pdf",
                      ""
                    )}/${content?.imgContenido}`
              }
              alt=""
            />
          </div>
          <div className="Titulo">{content?.nombreContenido}</div>
        </article>
      </Link>
    </>
  );
}

export default ContentCard;
