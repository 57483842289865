import styled from "@emotion/styled";
import { Autocomplete, TextField } from "@mui/material";

export const CssAutocomplete = styled(Autocomplete)({
  width: "100%",
  backgroundColor: "#F8F8F8",
  height: "35px",
  fontSize: "12px",
  border: "1px solid #D2DCE3",
  borderRadius:'5px',
  marginBottom: '20px',
  marginTop: '5px',
  "& label": {
    color: "#747474",
    fontSize: "12px",
  },
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    border: "#D2DCE3",
  },
  "& .MuiOutlinedInput-root": {
    fontSize: "12px",
    height: "30px",
    "& fieldset": {
      border: "#D2DCE3",
    },
    "&:hover fieldset": {
      borderColor: "##D2DCE3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D2DCE3",
      height: "30px",
    },
  },
});

export const CssTextField = styled(TextField)({
  height: "30px",
  fontSize: "12px",
  "& label": {
    color: "#747474",
    fontSize: "12px",
  },
  "& .MuiOutlinedInput-root": {
    fontSize: "12px",
    height: "30px",
  },
});
