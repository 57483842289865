import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  createFilterOptions,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BannerWelcome from "../../components/BannerWelcome/BannerWelcome";
import Footer from "../../components/Footer/Footer";
import Logo from "../../components/Logo/Logo";
import { CssTextField } from "../../components/Search/CssAutocomplete";
import {
  configAxios,
  ROUTES_INSTITUCIONS,
  ROUTES_USUARIOS,
} from "../../utils/AxiosAPI";
import {
  fmtCities,
  fmtDeparments,
  fmtOptionsAutoCompleteSignin,
} from "../../utils/utils";
import "./SignIn.scss";
import { delay } from "../../utils/utils";
import { Alert } from "@mui/material";

function SignIn() {
  const [userData, setUserData] = useState({});
  const [deparmetsList, setDepartmentsList] = useState([]);
  const [citiesList, setCitiestsList] = useState([]);
  const [department, setDepatment] = useState("");
  const [city, setcity] = useState("");
  const [genero, setGenero] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepit, setShowPasswordRepit] = useState(false);
  const [autocoCompleteValue, setAutoCompleteValue] = useState("");
  const [autocoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [createErr, setCreateErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const generos = [
    "Masculino",
    "Femenino",
    "No binario",
    "Prefieron no decirlo",
  ];
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, 4);
  };

  const userLocalStorage = localStorage.getItem("usuario")
    ? JSON.parse(localStorage.getItem("usuario"))
    : null;

  const userVerification = userLocalStorage !== null;

  const andminCodeVerification = userData.codigo === "291be4be-075a-45b5-9a7e-dfea0a6ef41d";

  const navigate = useNavigate();

  const getDeparments = async () => {
    try {
      const res = await axios.get(
        "https://raw.githubusercontent.com/marcovega/colombia-json/master/colombia.min.json"
      );
      const deparments = fmtDeparments(res.data);
      const cities = fmtCities(res.data, department);
      setDepartmentsList(deparments);
      setCitiestsList(cities);
    } catch (err) {
      console.log(err);
    }
  };

  const getInstituciones = async () => {
    try {
      const res = await axios.get(
        ROUTES_INSTITUCIONS.getAllWhitUsers,
        configAxios
      );
      const data = res?.data?.data?.sort((a, b) =>
        a.nombreInstitucion.localeCompare(b.nombreInstitucion)
      );
      const autoCompleteOptions = fmtOptionsAutoCompleteSignin(data);
      setAutoCompleteOptions(autoCompleteOptions);
    } catch (err) {
      console.log(err);
    }
  };

  const createUser = async () => {
    if (
      Object.keys(userData).length !== 8 ||
      autocoCompleteValue === "" ||
      passwordErr !== ""
    ) {
      setCreateErr("Todos los campos deben estar completos.");
    } else {
      try {
        const res = await axios.post(
          ROUTES_USUARIOS.create,
          {
            codigo: userData.codigo,
            nombreUsuario: userData.nombre,
            apellidoUsuario: userData.apellido,
            generoUsuario: genero,
            emailUsuario: userData.email,
            contrasenaUsuario: userData.contrasena,
            ciudadUsuario: city,
            tipoUsuario: 0,
            instituciones_id: userData.instituciones_id,
            clientes_id: userData.clientes_id,
            instituciones_clientes_id: userData.instituciones_clientes_id,
            ultimoContenido: null
          },
          configAxios
        );
        res.data.status !== "ok"
          ? setCreateErr("El codigo o la institucion estan incorrectos.")
          : await delay(3000);
            navigate("/login");
      } catch (error) {
        setCreateErr("Error al crear usuario, revise los datos.");
      }
    }
  };

  const createAdmin = async () => {
    if (Object.keys(userData).length !== 5 || passwordErr !== "") {
      setCreateErr("Todos los campos deben estar completos.");
    } else {
      try {
        const res = await axios.post(
          ROUTES_USUARIOS.createAdmin,
          {
            codigo: userData.codigo,
            nombreUsuario: userData.nombre,
            apellidoUsuario: userData.apellido,
            generoUsuario: genero,
            emailUsuario: userData.email,
            contrasenaUsuario: userData.contrasena,
            ciudadUsuario: city,
            tipoUsuario: 1,
            instituciones_id: "ff1c3686-db9c-429b-989f-97e55d70af46",
            clientes_id: "990ba0fe-6471-4290-8355-cc236370574d",
            instituciones_clientes_id: "990ba0fe-6471-4290-8355-cc236370574d",
            ultimoContenido: null
          },
          configAxios
        );
        res.data.status !== "ok"
          ? setCreateErr("El codigo o la institucion estan incorrectos.")
          : navigate("/login");
      } catch (error) {
        setCreateErr("Error al crear usuario, revise los datos.");
      }
    }
  };

  const createAccountHandler = () => {
    andminCodeVerification ? createAdmin() : createUser();
  };

  useEffect(() => {
    getInstituciones();
    if (userVerification) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getDeparments();
  }, [department]);

  if (!userVerification) {
    return (
      <>
        <main className="signIn">
          <Logo forPage="log" />
          <section className="signIn__banner">
            <BannerWelcome isSignIn={true} />
          </section>
          <section className="signIn__formContainer">
            <form autoComplete="off">
              <h1>Crear cuenta</h1>
              <h6>Informacion personal</h6>
              <div className="signIn__formContainerTowcol">
                <InputLabel
                  htmlFor="Nombre"
                  className="signIn__formContainerLabelMid"
                >
                  Nombre
                  <OutlinedInput
                    id="Nombre"
                    placeholder="Tu nombre"
                    className="signIn__formContainerInput signIn__formContainerInputMid"
                    inputProps={{ style: inputStyle }}
                    onChange={(e) => {
                      setUserData({ ...userData, nombre: e.target.value });
                      setCreateErr("");
                    }}
                  />
                </InputLabel>

                <InputLabel
                  htmlFor="Apellido"
                  className="signIn__formContainerLabelMid"
                >
                  Apellido
                  <OutlinedInput
                    id="Apellido"
                    placeholder="Tu apellido"
                    className="signIn__formContainerInput signIn__formContainerInputMid"
                    inputProps={{ style: inputStyle }}
                    onChange={(e) => {
                      setUserData({ ...userData, apellido: e.target.value });
                      setCreateErr("");
                    }}
                  />
                </InputLabel>
              </div>

              <InputLabel htmlFor="Email">
                Email
                <OutlinedInput
                  id="Email"
                  type="email"
                  placeholder="Tu email"
                  className="signIn__formContainerInput signIn__formContainerInputLarge"
                  inputProps={{ style: inputStyle }}
                  onChange={(e) => {
                    setUserData({ ...userData, email: e.target.value });
                    setCreateErr("");
                  }}
                />
              </InputLabel>

              <div className="signIn__formContainerTowcol">
                <InputLabel
                  htmlFor="departamento"
                  className="signIn__formContainerLabelDeparment"
                >
                  Departamento
                  <Select
                    displayEmpty
                    id="departamento"
                    value={department}
                    className="signIn__formContainerInputSelect"
                    size="small"
                    renderValue={(selected) => {
                      if (selected.length) {
                        return <em>{department}</em>;
                      }
                    }}
                  >
                    {deparmetsList.map((i, index) => (
                      <MenuItem
                        key={index}
                        value={i}
                        onClick={() => {
                          setDepatment(i);
                          setcity("");
                          setCreateErr("");
                        }}
                      >
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                </InputLabel>

                <InputLabel
                  htmlFor="ciudad"
                  className="signIn__formContainerLabelCity"
                >
                  Ciudad
                  <Select
                    displayEmpty
                    id="ciudad"
                    value={city}
                    className="signIn__formContainerInputSelect"
                    size="small"
                    renderValue={(selected) => {
                      if (selected.length) {
                        return <em>{city}</em>;
                      }
                    }}
                  >
                    {citiesList.map((i, index) => (
                      <MenuItem
                        key={index}
                        value={i}
                        onClick={() => {
                          setcity(i);
                          setCreateErr("");
                        }}
                      >
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                </InputLabel>
              </div>
              <div className="signIn__formContainerOnecol">
                <InputLabel htmlFor="genero">Genero</InputLabel>
                <Select
                  id="genero"
                  value={genero}
                  size="small"
                  className="signIn__formContainerOnecolSelect"
                  renderValue={(selected) => {
                    if (selected.length) {
                      return <em>{genero}</em>;
                    }
                  }}
                >
                  {generos.map((genero) => (
                    <MenuItem
                      key={genero}
                      value={genero}
                      onClick={() => {
                        setGenero(genero);
                        setCreateErr("");
                      }}
                    >
                      {genero}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className="signIn__formContainerTowcol">
                <InputLabel htmlFor="pasword">
                  Ingresa contraseña
                  <OutlinedInput
                    id="pasword"
                    placeholder="Tu contraseña"
                    className="signIn__formContainerInput signIn__formContainerInputMid"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      setUserData({ ...userData, contrasena: e.target.value });
                      setCreateErr("");
                      if (e.target.value.length < 8) {
                        setPasswordErr(
                          "La contrasena debe tener almenos 8 caracteres"
                        );
                      } else {
                        setPasswordErr("");
                      }
                    }}
                    endAdornment={
                      showPassword ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={() => setShowPassword(!showPassword)}
                          onMouseLeave={() => setShowPassword(false)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={() => setShowPassword(!showPassword)}
                          onMouseLeave={() => setShowPassword(false)}
                          style={{ cursor: "pointer" }}
                        />
                      )
                    }
                  />
                </InputLabel>

                <InputLabel htmlFor="passwordRepit">
                  Repite contraseña
                  <OutlinedInput
                    id="passwordRepit"
                    placeholder="Repite tu contraseña"
                    className="signIn__formContainerInput signIn__formContainerInputMid"
                    type={
                      showPasswordRepit || showPassword ? "text" : "password"
                    }
                    onChange={(e) => {
                      if (e.target.value !== userData.contrasena) {
                        setPasswordErr("Las contrasenas no coinciden");
                      } else {
                        setPasswordErr("");
                      }
                    }}
                    endAdornment={
                      showPasswordRepit ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() =>
                            setShowPasswordRepit(!showPasswordRepit)
                          }
                          onMouseDown={() =>
                            setShowPasswordRepit(!showPasswordRepit)
                          }
                          onMouseLeave={() => setShowPassword(false)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          onClick={() =>
                            setShowPasswordRepit(!showPasswordRepit)
                          }
                          onMouseDown={() =>
                            setShowPasswordRepit(!showPasswordRepit)
                          }
                          onMouseLeave={() => setShowPassword(false)}
                          style={{ cursor: "pointer" }}
                        />
                      )
                    }
                  />
                </InputLabel>
              </div>
              <p style={{ color: "red" }}>{passwordErr}</p>
              <h6>Credenciales</h6>

              <div className="signIn__formContainerOnecol">
                <InputLabel htmlFor="institucion">Institucion</InputLabel>
                <Autocomplete
                  id="institucion"
                  size="small"
                  disabled={andminCodeVerification}
                  className="signIn__formContainerOnecolInput"
                  filterOptions={filterOptions}
                  options={autocoCompleteOptions}
                  disableListWrap={true}
                  value={autocoCompleteValue}
                  isOptionEqualToValue={(option, value) =>
                    option.title === value.title
                  }
                  onChange={(event, newValue) => {
                    setAutoCompleteValue(
                      newValue !== null ? newValue.label : ""
                    );
                    setUserData({
                      ...userData,
                      instituciones_id: newValue.id,
                      clientes_id: newValue.clienteId,
                      instituciones_clientes_id: newValue.clienteId,
                    });
                    setCreateErr("");
                  }}
                  renderInput={(params) => (
                    <CssTextField {...params} sx={{ fontSize: "12px" }} />
                  )}
                />
              </div>

              <div className="signIn__formContainerOnecol">
                <InputLabel htmlFor="codigo">Codigo</InputLabel>
                <OutlinedInput
                  id="codigo"
                  placeholder="Codigo a canjear"
                  className="signIn__formContainerOnecolInputCodigo"
                  inputProps={{ style: inputStyle }}
                  onChange={(e) => {
                    setUserData({ ...userData, codigo: e.target.value });
                    setCreateErr("");
                  }}
                />
              </div>
              {createErr !== "" ? (
                <Alert severity="error">{createErr}</Alert>
              ) : (
                <p>
                  CUIDADO: El sistema discrimina mayúsculas de minúsculas a la
                  hora de validar el código
                </p>
              )}
            </form>
            <button
              className="signIn__formContainerButton"
              onClick={(e) => {
                e.preventDefault();
                createAccountHandler();
              }}
            >
              Crear cuenta
            </button>
            <button
              className="signIn__formContainerButtonDos"
              onClick={(e) => {
                e.preventDefault();
                navigate("/login");
              }}
            >
              Cancelar
            </button>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default SignIn;
