import { faCircleUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BannerWelcome from "../../components/BannerWelcome/BannerWelcome";
import Footer from "../../components/Footer/Footer";
import Logo from "../../components/Logo/Logo";
import ModalRecordarPass from "../../components/ModalRecordarPass/ModalRecordarPass";
import { configAxios, ROUTES_USUARIOS } from "../../utils/AxiosAPI";
import { delay } from "../../utils/utils";
import "./LogIn.scss";
import { CssTextField, inputStyle } from "./textfiel";
import { Alert } from "@mui/material";

function LogIn() {
    const [userData, setUserData] = useState({
        emailUsuario: "",
        contrasenaUsuario: "",
    });

    const [emailData, setEmailData] = useState({
        emailUsuario: "",
    });

    const [emailPass, setEmailPass] = useState({
        to: "",
        subject: "",
        link: "",
    });

    const [errorEmail, setErrorEmail] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
        setSendEmail(false);
        setErrorEmail(false);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const consultarEmail = useCallback(async () => {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_URL_BACKEND}users/mail`,
                emailData,
                configAxios,
            );
            if (res.data.status === "fail") {
                setErrorEmail(true);
            } else {
                setEmailPass({
                    ...emailData,
                    to: res.data.data[0].emailUsuario,
                    subject: "Recuperación de Contraseña",
                    link: `${process.env.REACT_APP_URL_FRONTEND}#/recordpass/${res.data.data[0].id}`,
                });
            }
        } catch (err) {
            console.log(err);
        }
    });

    const enviarEmail = useEffect(() => {
        if (emailPass.to !== "" && emailPass.subject !== "" && emailPass.link !== "") {
            try {
                axios
                    .post(
                        `${process.env.REACT_APP_URL_BACKEND}mail/recuperar`,
                        emailPass,
                        configAxios,
                    )
                    .then(res => {
                        setSendEmail(true);
                        setErrorEmail(false);
                        handleClose();
                    })
                    .catch(err => {
                        console.log("Error send mail", err);
                        setErrorEmail(true);
                    });
            } catch (err) {
                console.log(err);
            }
        }
    }, [emailPass]);

    const [logInErr, setLogInErr] = useState(null);

    const navigate = useNavigate();

    const userLocalStorage = localStorage.getItem("usuario")
        ? JSON.parse(localStorage.getItem("usuario"))
        : null;

    const userVerification = userLocalStorage !== null;

    const createSession = async () => {
        try {
            const res = await axios.post(ROUTES_USUARIOS.session, userData, configAxios);
            if (res.data.status !== "fail") {
                localStorage.setItem("usuario", JSON.stringify(res.data.data));
                navigate("/");
            } else {
                setLogInErr("Las credenciales no coinciden");
            }
            console.log(res);
        } catch (error) {
            setLogInErr("Error al iniciar sesion");
        }
    };

    const LogInHanddler = () => {
        if (userData.emailUsuario !== "" && userData.contrasenaUsuario !== "") {
            createSession();
        } else {
            setLogInErr("Los campos no pueden estar vacios");
        }
    };

    useEffect(() => {
        if (userVerification) {
            navigate("/");
        }
    }, []);

    if (!userVerification) {
        return (
            <>
                <main className="login">
                    <section className="login__banner">
                        <Logo forPage="log" />
                        <BannerWelcome />
                    </section>
                    <section className="login__container">
                        <h1>Iniciar sesión</h1>
                        <form>
                            <div className="login__containerInput">
                                <FontAwesomeIcon
                                    icon={faCircleUser}
                                    className="login__containerInputIcon"
                                />
                                <CssTextField
                                    id="email"
                                    label="Ingresa tu correo"
                                    type="email"
                                    inputProps={{ style: inputStyle }}
                                    onChange={e => {
                                        setUserData({
                                            ...userData,
                                            emailUsuario: e.target.value,
                                        });
                                        setLogInErr("");
                                    }}
                                />
                            </div>
                            <div className="login__containerInput">
                                <FontAwesomeIcon
                                    icon={faLock}
                                    className="login__containerInputIcon"
                                />
                                <CssTextField
                                    id="password"
                                    label="Ingresa tu contraseña"
                                    type="password"
                                    inputProps={{ style: inputStyle }}
                                    onChange={e => {
                                        setUserData({
                                            ...userData,
                                            contrasenaUsuario: e.target.value,
                                        });
                                        setLogInErr("");
                                    }}
                                />
                            </div>

                            <Link
                                to="#"
                                className="login__containerRecover"
                                onClick={handleOpen}>
                                Olvidé mi contraseña
                            </Link>
                            <ModalRecordarPass
                                open={open}
                                handleClose={handleClose}
                                title="Recordar Contraseña"
                                action={consultarEmail}
                                setEmailData={setEmailData}
                                emailData={emailData}
                                errorEmail={errorEmail}
                                sendEmail={sendEmail}
                            />
                            {logInErr && <Alert severity="error">{logInErr}</Alert>}
                            <div className="login__containerButtons">
                                <button
                                    className="login__containerButtonsLog"
                                    onClick={e => {
                                        e.preventDefault();
                                        LogInHanddler();
                                    }}>
                                    Acceder
                                </button>
                                <button
                                    className="login__containerButtonsCreate"
                                    onClick={e => {
                                        e.preventDefault();
                                        navigate("/signin");
                                    }}>
                                    Crear cuenta
                                </button>
                            </div>
                        </form>
                    </section>
                </main>
                <Footer />
            </>
        );
    }
}

export default LogIn;
