import axios from "axios";

export const configAxios = {
    headers: {
        Authorization: process.env.REACT_APP_AUTHORIZATION_TOKEN,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    },
};

export const configAxiosUpload = {
    headers: {
        Authorization: process.env.REACT_APP_AUTHORIZATION_TOKEN,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
    },
};

export const validarEmail = async () => {
    const data = await axios.get(`${process.env.REACT_APP_URL_BACKEND}users/mail`, configAxios);
    return data;
};

export const RUOTES_CLIENTS = {
    getAll: `${process.env.REACT_APP_URL_BACKEND}clientes`,
    getByID: `${process.env.REACT_APP_URL_BACKEND}clientes/client/`,
    getAllInstituciones: `${process.env.REACT_APP_URL_BACKEND}clientes/include/instituciones`,
    createClient: `${process.env.REACT_APP_URL_BACKEND}clientes`,
    editDelete: `${process.env.REACT_APP_URL_BACKEND}clientes/client/`,
};

export const ROUTES_INSTITUCIONS = {
    getAllWhitUsers: `${process.env.REACT_APP_URL_BACKEND}instituciones/include/usuarios`,
    createInstitucion: `${process.env.REACT_APP_URL_BACKEND}instituciones`,
    editDelete: `${process.env.REACT_APP_URL_BACKEND}instituciones/institucion/`,
    getByID: `${process.env.REACT_APP_URL_BACKEND}instituciones/institucion/`,
};

export const ROUTES_CONTENIDOS = {
    getAllContenidos: `${process.env.REACT_APP_URL_BACKEND}contenidos`,
    getByID: `${process.env.REACT_APP_URL_BACKEND}contenidos/`,
    createContenido: `${process.env.REACT_APP_URL_BACKEND}contenidos`,
    editDelete: `${process.env.REACT_APP_URL_BACKEND}contenidos/`,
    upload: `${process.env.REACT_APP_URL_BACKEND}contenidos/upload`,
};

export const ROUTES_DESCARGABLES = {
    getAll: `${process.env.REACT_APP_URL_BACKEND}files`,
    create: `${process.env.REACT_APP_URL_BACKEND}files`,
    delete: `${process.env.REACT_APP_URL_BACKEND}files/file/`,
    upload: `${process.env.REACT_APP_URL_BACKEND}files/upload`,
};

export const ROUTES_USUARIOS = {
    getAll: `${process.env.REACT_APP_URL_BACKEND}users`,
    create: `${process.env.REACT_APP_URL_BACKEND}users`,
    createAdmin: `${process.env.REACT_APP_URL_BACKEND}users/usersAdmin`,
    delete: `${process.env.REACT_APP_URL_BACKEND}users/user/`,
    session: `${process.env.REACT_APP_URL_BACKEND}users/session`,
};

export const ROUTES_CODIGOS = {
    getAll: `${process.env.REACT_APP_URL_BACKEND}codigos`,
    create: `${process.env.REACT_APP_URL_BACKEND}codigos`,
};

export const ROUTES_CONTENIDOS_INSTITUCIONES = {
    byContenido: `${process.env.REACT_APP_URL_BACKEND}contenidosForUsers/contenido/`,
    byInstitucion: `${process.env.REACT_APP_URL_BACKEND}contenidosForUsers/institucion/`,
    create: `${process.env.REACT_APP_URL_BACKEND}contenidosForUsers`,
    delete: `${process.env.REACT_APP_URL_BACKEND}contenidosForUsers/`,
};

export const ROUTES_MAIL = {
    sendCodes: `${process.env.REACT_APP_URL_BACKEND}mail/`,
};
