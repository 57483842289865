import './CodeTag.scss'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CodeTag = ({txt, icon, data}) => {
  return (
    <div className='CodeTag'>
        <h5>{data}</h5>
        <div>
            <FontAwesomeIcon icon={icon} />
            <p>{txt}</p>
        </div>
    </div>
  )
}

export default CodeTag