import "./ModalDownload.scss";
import React, { useState } from "react";
import { Modal, InputLabel, OutlinedInput } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { configAxiosUpload, ROUTES_DESCARGABLES } from "../../utils/AxiosAPI";

const ModalDownload = ({
  open,
  handleClose,
  action,
  data,
  setData,
  fileName,
  setFileName,
  contenido
}) => {
  const [file, setFile] = useState();

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("name", `${file.name}`);
    formData.append("id", `contenido_${contenido.fileContenido.replace(".pdf", "")}`);
    formData.append("file", file);
    formData.append("fileName", file.name);
    try {
      const res = await axios.post(
        ROUTES_DESCARGABLES.upload,
        formData,
        configAxiosUpload
      );
    } catch (error) {
      console.log(error);
    }
    setFileName("Seleccionar un archivo");
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdropFilter: "blur(8px)" }}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0)" } }}
    >
      <div className="ModalDownload">
        <h2>Añadir descargable</h2>
        <InputLabel htmlFor="Nombre" sx={{ width: "100%" }}>
          Nombre del descargable
          <OutlinedInput
            id="Nombre"
            placeholder="Descargable"
            onChange={e => {
              setData({
                ...data,
                nombreDescargable: e.target.value,
              });
            }}
          />
        </InputLabel>
        <div className="ModalDownload__file">
          <h3>Subir descargable</h3>
          <input
            type="file"
            name="file"
            id="file"
            onChange={e => {
              const file = e.target.files[0].name;
              setData({
                ...data,
                fileDescargable: file,
              });
              setFileName(file);
              setFile(e.target.files[0]);
            }}
          />
          <label htmlFor="file">
            <FontAwesomeIcon
              icon={faCloudUpload}
              className="ModalDownload__fileIcon"
            />
            {fileName}
          </label>
        </div>
        <div className="ModalDownload__buttons">
          <button
            onClick={e => {
              e.preventDefault();
              action();
              uploadFile();
            }}
          >
            Añadir descargable
          </button>
          <button
            onClick={e => {
              e.preventDefault();
              handleClose();
            }}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDownload;
