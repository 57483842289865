import "./ModalClients.scss";
import React, { useEffect, useState } from "react";
import {
  Modal,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { fmtCities, fmtDeparments } from "../../utils/utils";

const ModalClients = ({
  open,
  handleClose,
  action,
  edit = false,
  setClientData,
  clientData = false,
  asInstitucion = false,
  setAsInstitucion = false,
}) => {
  const [deparmetsList, setDepartmentsList] = useState([]);
  const [citiesList, setCitiestsList] = useState([]);
  const [department, setDepatment] = useState("");
  const [city, setcity] = useState("");

  const getDeparments = async () => {
    try {
      const res = await axios.get(
        "https://raw.githubusercontent.com/marcovega/colombia-json/master/colombia.min.json"
      );
      const deparments = fmtDeparments(res.data);
      const cities = fmtCities(res.data, department);
      setDepartmentsList(deparments);
      setCitiestsList(cities);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!edit) {
      setClientData({})
    }
  },[])

  useEffect(() => {
    getDeparments();
  }, [department]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdropFilter: "blur(8px)" }}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0)" } }}
    >
      <div className="ModalClients">
        <h2>{edit ? "Editar cliente" : "Agregar nuevo cliente"}</h2>
        <InputLabel htmlFor="Nombre" sx={{ width: "100%" }}>
          Nombre del cliente
          <OutlinedInput
            id="Nombre"
            placeholder="Cliente"
            defaultValue={clientData.nombreCliente}
            className="signIn__formContainerInput signIn__formContainerInputMid"
            onChange={e => {
              setClientData({
                ...clientData,
                nombreCliente: e.target.value,
              });
            }}
          />
        </InputLabel>
        <div className="ModalClients__place">
          <InputLabel
            htmlFor="departamento"
            className="signIn__formContainerLabelDeparment"
          >
            Departamento
            <Select
              displayEmpty
              id="departamento"
              value={department}
              className="signIn__formContainerInputSelect"
              size="small"
              renderValue={selected => {
                if (selected.length === 0 && clientData) {
                  return <em>{clientData.departamentoCliente}</em>;
                } else {
                  return <em>{department}</em>;
                }
              }}
            >
              {deparmetsList.map((i, index) => (
                <MenuItem
                  key={index}
                  value={i}
                  onClick={() => {
                    setDepatment(i);
                    setcity("");
                    setClientData({
                      ...clientData,
                      departamentoCliente: i,
                    });
                  }}
                >
                  {i}
                </MenuItem>
              ))}
            </Select>
          </InputLabel>

          <InputLabel
            htmlFor="ciudad"
            className="signIn__formContainerLabelCity"
          >
            Ciudad
            <Select
              displayEmpty
              id="ciudad"
              value={city}
              className="signIn__formContainerInputSelect"
              size="small"
              renderValue={selected => {
                if (selected.length === 0 && clientData) {
                  return <em>{clientData.ciudadCliente}</em>;
                } else {
                  return <em>{city}</em>;
                }
              }}
            >
              {citiesList.map((i, index) => (
                <MenuItem
                  key={index}
                  value={i}
                  onClick={() => {
                    setcity(i);
                    setClientData({
                      ...clientData,
                      ciudadCliente: i,
                    });
                  }}
                >
                  {i}
                </MenuItem>
              ))}
            </Select>
          </InputLabel>
        </div>
        <div className="ModalClients__date">
          <label htmlFor="dateStart">
            Fecha de inicio
            <input
              type="date"
              name="date"
              id="dateStart"
              defaultValue={clientData.inicio}
              onChange={e => {
                setClientData({
                  ...clientData,
                  inicio: e.target.value,
                });
              }}
            />
          </label>
          <label htmlFor="dateEnd">
            Fecha final
            <input
              type="date"
              name="date"
              id="dateEnd"
              defaultValue={clientData.final}
              onChange={e => {
                setClientData({
                  ...clientData,
                  final: e.target.value,
                });
              }}
            />
          </label>
        </div>
        {!edit && (
          <div className="ModalClients__institucion">
            <label htmlFor="institucion" className="ModalClients__institucion">
              Agregar como institución
            </label>
            <input
              type="checkbox"
              name="institucion"
              id="institucion"
              onChange={() => setAsInstitucion(!asInstitucion)}
            />
          </div>
        )}
        <div className="ModalClients__buttons">
          <button
            onClick={e => {
              e.preventDefault();
              action();
            }}
          >
            {edit ? "Editar cliente" : "Crear cliente"}
          </button>
          <button
            onClick={e => {
              e.preventDefault();
              handleClose();
            }}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalClients;
