import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import Loading from "../../components/Loading/Loading";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import ModalInstitucion from "../../components/ModalInstitucion/ModalInstitucion";
import Search from "../../components/Search/Search";
import TableAdmin from "../../components/TableAdmin/TableAdmin";
import {
  configAxios,
  ROUTES_INSTITUCIONS,
  RUOTES_CLIENTS
} from "../../utils/AxiosAPI";
import { fmtMonth, fmtOptionsAutoComplete } from "../../utils/utils";
import "./AdminInstitucion.scss";

const AdminInstitucion = () => {
  const thead = [
    "Nombre",
    "Ciudad",
    "Departamento",
    "No. proyecto",
    "Fecha inicio",
    "Fecha final",
    "No. de usuarios",
  ];

  const [rowsInstitucions, setRowsInstitucions] = useState([]);
  const [FilteredData, setFilteredData] = useState([]);

  const [institucionData, setInstitucion] = useState({});
  const [institucionToEdit, setInstitucionToEdit] = useState({});

  const [clientList, setClientList] = useState([]);

  const autoCompleteOptions = fmtOptionsAutoComplete(
    rowsInstitucions,
    "nombreInstitucion"
  );

  const autoCompleteClients = fmtOptionsAutoComplete(
    clientList,
    "nombreCliente"
  );

  const [autocoCompleteValue, setAutoCompleteValue] = useState("");

  const [haspageLoaded, setHasPageLoaded] = useState(false);

  const [deleteModal, setModalDelete] = useState(false);
  const handleOpenDelete = institucionData => {
    setModalDelete(true);
    setInstitucionToEdit(institucionData);
  };
  const handleCloseDelete = () => setModalDelete(false);

  const [modal, setModal] = useState(false);
  const handleOpen = () => setModal(true);
  const handleClose = () => {
    setModal(false);
    setInstitucion({});
  };

  const [modalEdit, setModalEdit] = useState(false);
  const handleOpenEdit = institucionData => {
    setModalEdit(true);
    setInstitucionToEdit(institucionData);
    setInstitucion(institucionData);
  };
  const handleCloseEdit = () => {
    setModalEdit(false);
    setInstitucion({});
  };

  const [dateStartFilter, setDateStartFilter] = useState("");
  const [dateEndFilter, setDateEndFilter] = useState("");

  const getData = async () => {
    try {
      const res = await axios.get(
        ROUTES_INSTITUCIONS.getAllWhitUsers,
        configAxios
      );
      const data = res?.data?.data?.sort((a, b) =>
        a.nombreInstitucion.localeCompare(b.nombreInstitucion)
      );
      setRowsInstitucions(data);
      setFilteredData(data);
      setHasPageLoaded(true);

      const clientList = await axios.get(RUOTES_CLIENTS.getAll, configAxios);
      const dataClient = clientList?.data?.data?.sort((a, b) =>
        a.nombreCliente.localeCompare(b.nombreCliente)
      );
      setClientList(dataClient);
    } catch (err) {
      console.log(err);
    }
  };

  const createInstitucion = useCallback(async () => {
    try {
      await axios.post(
        ROUTES_INSTITUCIONS.createInstitucion,
        institucionData,
        configAxios
      );
      handleClose();
      getData();
    } catch (err) {
      console.log(err);
    }
  }, [institucionData]);

  const handlerDelete = useCallback(async () => {
    try {
      const res = await axios.delete(
        `${ROUTES_INSTITUCIONS.editDelete}${institucionToEdit.id}`,
        configAxios
      );
      if (res.status > 199 || res.status < 300) {
        handleCloseDelete();
        getData();
      }
    } catch (err) {
      console.log(err);
    }
    setInstitucion({});
  }, [institucionToEdit]);

  const handlerEdit = useCallback(async () => {
    try {
      const res = await axios.put(
        `${ROUTES_INSTITUCIONS.editDelete}${institucionToEdit.id}`,
        institucionData,
        configAxios
      );
      if (res.status > 199 || res.status < 300) {
        handleCloseEdit();
        getData();
      }
    } catch (err) {
      console.log(err);
    }
    setInstitucion({});
  }, [institucionData, institucionToEdit]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const filterdByDate = rowsInstitucions
      .filter(row => {
        return dateStartFilter === ""
          ? row
          : fmtMonth(row.inicio) === dateStartFilter;
      })
      .filter(row => {
        return dateEndFilter === ""
          ? row
          : fmtMonth(row.final) === dateEndFilter;
      })
      .filter(row => {
        return autocoCompleteValue === ""
          ? row
          : row.nombreInstitucion === autocoCompleteValue;
      });

    setFilteredData(filterdByDate);
  }, [dateStartFilter, dateEndFilter, autocoCompleteValue, rowsInstitucions]);

  return (
    <main className="adminInstitucion">
      <HeaderAdmin
        title="Administración de instituciones"
        text="Lista de instituciones"
        bgColor="#25C8F0"
      />
      <section className="adminInstitucion__content">
        {haspageLoaded ? (
          <>
            <Search
              label="Buscar una institucion"
              options={autoCompleteOptions}
              buttonTxt="Agregar nueva institucion"
              action={handleOpen}
              setDateStart={setDateStartFilter}
              setDateEnd={setDateEndFilter}
              autocoCompleteValue={autocoCompleteValue}
              setAutoCompleteValue={setAutoCompleteValue}
            />
            <TableAdmin
              colums={thead}
              rows={FilteredData}
              editOpt={handleOpenEdit}
              deleteOpt={handleOpenDelete}
              total="usuarios"
            />
            <ModalDelete
              open={deleteModal}
              handleClose={handleCloseDelete}
              title="Eliminar institucion"
              name={institucionToEdit.nombreInstitucion}
              action={handlerDelete}
              txtAction="Eliminar institucion"
            />

            <ModalInstitucion
              open={modal}
              handleClose={handleClose}
              action={createInstitucion}
              institucionData={institucionData}
              setInstitucion={setInstitucion}
              options={autoCompleteClients}
            />

            <ModalInstitucion
              open={modalEdit}
              handleClose={handleCloseEdit}
              action={handlerEdit}
              institucionData={institucionData}
              setInstitucion={setInstitucion}
              options={autoCompleteClients}
              edit={institucionToEdit}
            />
          </>
        ) : (
          <Loading />
        )}
      </section>
    </main>
  );
};

export default AdminInstitucion;
