import React from "react";
import "./ModalRecordadPass.scss";
import { Modal, TextField, InputLabel, Alert } from "@mui/material";
import Collapse from "@mui/material/Collapse";

const ModalRecordarPass = ({
  open,
  handleClose,
  title,
  action,
  setEmailData,
  emailData,
  errorEmail,
  sendEmail,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdropFilter: "blur(4px)" }}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0)" } }}
    >
      <div className="ModalRecuperarPass">
        <h2>{title}</h2>
        <InputLabel htmlFor="email" sx={{ width: "100%" }}>
          Correo electrónico:
        </InputLabel>
        <TextField
          sx={{ margin: "10px 0px" }}
          id="email"
          type="email"
          placeholder="mail@ejemplo.com"
          onChange={(e) => {
            setEmailData({
              ...emailData,
              emailUsuario: e.target.value,
            });
          }}
        />

        {errorEmail && (
          <Alert severity="error">
            Este correo no se encuentra en nuestra base de datos
          </Alert>
        )}
        {sendEmail && (
          <Alert>
            Correo de recuperación enviado a {emailData.emailUsuario}
          </Alert>
        )}

        <p>Recuerde que el correo debe estar registrado en la plataforma.</p>
        <div className="ModalPass__buttons">
          <button
            onClick={async (e) => {
              e.preventDefault();
              action();
            }}
          >
            Enviar
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRecordarPass;
