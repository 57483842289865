import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faLock } from "@fortawesome/free-solid-svg-icons";
import { Alert, Snackbar, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import BannerWelcome from "../../components/BannerWelcome/BannerWelcome";
import Footer from "../../components/Footer/Footer";
import Logo from "../../components/Logo/Logo";
import { configAxios } from "../../utils/AxiosAPI";
import { delay } from "../../utils/utils";
import "./RecordarPass.scss";

const CssTextField = styled(TextField)({
  width: "100%",
  "& label": {
    color: "#fff",
    fontSize: "20px",
  },
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    color: "#fff",
    fontSize: "20px",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "none",
    },
  },
});

const killSession = () => {
  localStorage.clear();
};

function RecordarPass() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [success, setSuccess] = useState("");
  const [mensaje, setMensaje] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleOk = async () => {
    try {
      if (pass1 === pass2) {
        if (pass1.length >= 8 && pass2.length >= 8) {
          await axios.put(
            `${process.env.REACT_APP_URL_BACKEND}users/user/${id}`,
            { contrasenaUsuario: pass1 },
            configAxios
          );
          setMensaje("Contraseña restaurada correctamente");
          setSuccess("success");
          setOpenAlert(true);
          await delay(3000);
          killSession();
          await delay(1000);
          navigate(`/login`);
        } else {
          setMensaje("La contraseña debe tener al menos 8 caracteres");
          setSuccess("error");
        }
      } else {
        setMensaje("Las contraseñas no coinciden, por favor verifique");
        setSuccess("error");
      }
      setOpenAlert(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <main className="login">
        <Logo forPage="log" />
        <section className="login__banner">
          <BannerWelcome />
        </section>
        <section className="login__container">
          <h1>Recuperar Contraseña</h1>
          <form>
            <div className="login__containerInput">
              <FontAwesomeIcon
                icon={faLock}
                className="login__containerInputIcon"
              />
              <CssTextField
                id="password1"
                label="Nueva contraseña"
                type="password"
                onChange={(e) => setPass1(e.target.value)}
              />
            </div>
            <div className="login__containerInput">
              <FontAwesomeIcon
                icon={faKey}
                className="login__containerInputIcon"
              />
              <CssTextField
                id="password2"
                label="Confirmar contraseña"
                type="password"
                onChange={(e) => setPass2(e.target.value)}
              />
            </div>
            <div className="login__containerButtons">
              <button
                className="login__containerButtonsLog"
                onClick={(e) => {
                  e.preventDefault();
                  handleOk();
                }}
              >
                Confirmar
              </button>
            </div>
          </form>
        </section>
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert onClose={handleClose} severity={success}>
            {mensaje}
          </Alert>
        </Snackbar>
      </main>
      <Footer />
    </>
  );
}

export default RecordarPass;
