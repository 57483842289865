import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import './IframeContainer2.scss'

const IframeContainer = ({button, src}) => {

  const userLocalStorage = localStorage.getItem("usuario")
    ? JSON.parse(localStorage.getItem("usuario"))
    : null;

  const navigate = useNavigate();

  const userVerification = userLocalStorage === null;

  useEffect(() => {
    if (userVerification) {
      navigate("/login");
    }
  }, []);

  if (!userVerification) {
    return (
      <div className="IframeContainerContent">
        {button && <Link to={"/"}>Volver a tknova</Link>}
        <iframe className="Iframe2"
          src={src}
          frameBorder="0"
        ></iframe>
      </div>
    );
  }
};

export default IframeContainer;
