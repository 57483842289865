import "./AdminUsers.scss";
import React, { useState, useEffect } from "react";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import {
  CssAutocomplete,
  CssTextField,
} from "../../components/Search/CssAutocomplete";
import axios from "axios";
import {
  configAxios,
  ROUTES_USUARIOS,
  ROUTES_INSTITUCIONS,
} from "../../utils/AxiosAPI";
import TableUsers from "../../components/TableUsers/TableUsers";
import { fmtOptionsAutoComplete } from "../../utils/utils";
import { createFilterOptions } from "@mui/material";
import Loading from "../../components/Loading/Loading";

const AdminUsers = () => {
  const thead = [
    "Nombre",
    "Correo asociado",
    "Fecha de creación",
    "Finalizacion del contrato",
    "ultima coneccion",
    "ciudad",
  ];

  const [usersList, setUsersList] = useState([]);
  const [instituciones, setInstituciones] = useState([]);
  const [institusionID, setInstitusionID] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [hasPageLoaded, setHasPageLoaded] = useState(false);
  const [autocoCompleteValue, setAutoCompleteValue] = useState("");

  const autoCompleteOptions = fmtOptionsAutoComplete(instituciones, "nombreInstitucion");

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, 4);
  };

  const getUsers = async () => {
    try {
      const users = await axios.get(ROUTES_USUARIOS.getAll, configAxios);
      setUsersList(users.data.data);
      setFilteredData(users.data.data);
      setHasPageLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getInstituciones = async () => {
    try {
      const res = await axios.get(
        ROUTES_INSTITUCIONS.getAllWhitUsers,
        configAxios
      );
      setInstituciones(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const filter = usersList.filter(row => {
      return institusionID === "" ? row : row.instituciones_id === institusionID;
    });
    setFilteredData(filter);
  }, [institusionID]);

  useEffect(() => {
    getUsers();
    getInstituciones();
  }, []);

  return (
    <main className="adminUsers">
      <HeaderAdmin
        title="Administración de usuarios"
        text="Lista de Usuairos"
        bgColor="#5800FF"
      />
      <section className="adminUsers__content">
        {hasPageLoaded ? (
          <>
            <div className="adminUsers__contentSearchUser">
              <h4>Cliente</h4>
              <CssAutocomplete
                id="searchUser"
                filterOptions={filterOptions}
                options={autoCompleteOptions}
                disableListWrap={true}
                style={{ borderRadius: "5px" }}
                value={autocoCompleteValue}
                onChange={(event, newValue) => {
                  setAutoCompleteValue(newValue !== null ? newValue.label : "");
                  setInstitusionID(newValue !== null ? newValue.id : "");
                }}
                isOptionEqualToValue={(option, value) =>
                  option.title === value.title
                }
                renderInput={params => (
                  <CssTextField
                    {...params}
                    placeholder={"Buscar un cliente"}
                    sx={{ fontSize: "12px" }}
                  />
                )}
                size="small"
              />
            </div>
            <TableUsers colums={thead} rows={filteredData} deleteOpt />
          </>
        ) : (
          <Loading />
        )}
      </section>
    </main>
  );
};

export default AdminUsers;
