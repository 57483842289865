import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import Stadistic from "../../components/Stadistic/Stadistic";
import {
  configAxios, ROUTES_CODIGOS, ROUTES_CONTENIDOS,
  ROUTES_DESCARGABLES, ROUTES_INSTITUCIONS, ROUTES_USUARIOS, RUOTES_CLIENTS
} from "../../utils/AxiosAPI";
import "./AdminSumary.scss";

export const AdminSumary = () => {
  const [clientList, setClientList] = useState([]);
  const [institucionesList, setInstitucionesList] = useState([]);
  const [contenidosList, setContenidosList] = useState([]);
  const [descargablesList, setDescargablesList] = useState([]);
  const [usuariosList, setUsuariosList] = useState([]);
  const [codigosList, setCodigosList] = useState([]);

  const getData = useCallback(async () => {
    try {
      const clientes = await axios.get(RUOTES_CLIENTS.getAll, configAxios);
      setClientList(clientes.data.data);

      const instituciones = await axios.get(
        ROUTES_INSTITUCIONS.getAllWhitUsers,
        configAxios
      );
      setInstitucionesList(instituciones.data.data);

      const contenidos = await axios.get(
        ROUTES_CONTENIDOS.getAllContenidos,
        configAxios
      );
      setContenidosList(contenidos.data.data);

      const descargables = await axios.get(
        ROUTES_DESCARGABLES.getAll,
        configAxios
      );
      setDescargablesList(descargables.data.data);

      const usuarios = await axios.get(ROUTES_USUARIOS.getAll, configAxios);
      setUsuariosList(usuarios.data.data);

      const codigos = await axios.get(ROUTES_CODIGOS.getAll, configAxios);
      setCodigosList(codigos.data.data);
    } catch (error) {
      console.log(error);
    }
  });

  useEffect(() => {
    getData();
  }, []);

  return (
    <main className="adminSummary">
      <HeaderAdmin
        title="Resumen general"
        text="Estado actual de usuarios y contenidos:"
        bgColor="#00009C"
      />
      <section className="adminSummary__content">
        <h2>Datos generales:</h2>
        <div className="adminSummary__contentGenerales">
          <div className="adminSummary__contentGeneralesRigth">
            <Stadistic
              data={clientList.length}
              text="Clientes inscritos"
              type="center"
              fs={40}
            />
          </div>
          <div className="adminSummary__contentGeneralesLeft">
            <Stadistic
              data={institucionesList?.length}
              text="Instituciones inscritas"
              type="left"
              fs={20}
            />
            <Stadistic
              data={contenidosList?.length}
              text="Contenidos en la web"
              type="left"
              fs={20}
            />
            <Stadistic
              data={descargablesList?.length}
              text="Descargables subidos"
              type="left"
              fs={20}
            />
            <Stadistic
              data={usuariosList?.length}
              text="Usuarios inscritos"
              type="left"
              fs={20}
            />
            <Stadistic
              data={codigosList?.length}
              text="Codigos creados"
              type="left"
              fs={20}
            />
            <Stadistic
              data={codigosList?.filter(i => i.emailVinculado !== null).length}
              text="Codigos canjeados"
              type="left"
              fs={20}
            />
          </div>
        </div>
      </section>
    </main>
  );
};
