import React from "react";
import "./Commits.scss";
import { randomColor } from "../../utils/utils";
import { useCallback } from "react";

function Commits({comment, usuario}) {
  const date = new Date(`${comment?.created_at}`).toLocaleDateString('es-co', { weekday:"long", year:"numeric", month:"long", day:"numeric"});
  const colorBG = useCallback(randomColor(), []);
  return (
    <>
      <section className="Commit">
        <div className="CommitUser">
          <div className="CommitUser__user">
            <div className="CommitUser__userName">
              <div className="CommitUser__userNameDiv" style={{ backgroundColor: colorBG }}>
              {usuario?.nombreUsuario[0]}
              {usuario?.apellidoUsuario[0]}
              </div>
            </div>
          </div>
        </div>
        <div className="Commit__title">
          <div>
            <h4>
              <span>{comment?.asuntoComentario}</span> - {usuario?.nombreUsuario} {usuario?.apellidoUsuario}
            </h4>
          </div>
          <p>
          {comment?.comentario}
          </p>
          <div className="Commit__Date">
            <p>{date}</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Commits;
