import "./ModalDelete.scss";
import { Modal } from "@mui/material";
import React from "react";

const ModalDelete = ({ open, handleClose, title, name, action, txtAction }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdropFilter: "blur(2px)" }}
      BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0)'}}}
    >
      <div className="ModalDelete">
        <h2>{title}</h2>
        <h4>{name}</h4>
        <p>Recuerde que esta acción es irreversible</p>
        <div>
          <button
            onClick={e => {
              e.preventDefault();
              action();
            }}
          >
            {txtAction}
          </button>
          <button
            onClick={e => {
              e.preventDefault();
              handleClose();
            }}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDelete;
