import "./ModalContent.scss";
import React, { useState } from "react";
import { Modal, InputLabel, OutlinedInput } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload, faImage } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import axios from "axios";
import { configAxiosUpload, ROUTES_CONTENIDOS } from "../../utils/AxiosAPI";

const ModalContent = ({
  open,
  handleClose,
  action,
  edit = false,
  data,
  setData,
}) => {
  const [fileName, setFileName] = useState("Seleccionar un archivo");
  const [portadaName, setPortadaName] = useState("Seleccionar un archivo");
  const [file, setFile] = useState();
  const [portada, setPortada] = useState();

  const removeAccents = (text) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  function getFileExtension(filename) {
    const extension = filename.split(".").pop();
    return extension;
  }

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append(
      "name",
      `${removeAccents(file.name.toLowerCase()).replace(/\s+/g, "_")}`
    );
    formData.append("id", `contenido_${file.name.replace(".pdf", "")}`);
    formData.append("fileName", file.name);
    formData.append("file", file);

    try {
      console.log(formData);
      await axios.post(ROUTES_CONTENIDOS.upload, formData, configAxiosUpload);
    } catch (error) {
      console.log(error);
    }
    setFileName("Seleccionar un archivo");
  };

  const uploadImage = async () => {
    const formData = new FormData();
    formData.append(
      "name",
      `${removeAccents(file.name.toLowerCase()).replace(/\s+/g, "_")}`
    );
    formData.append("id", `contenido_${file.name.replace(".pdf", "")}`);
    formData.append("fileName", portada.name);
    formData.append("file", portada);
    try {
      await axios.post(ROUTES_CONTENIDOS.upload, formData, configAxiosUpload);
    } catch (error) {
      console.log(error);
    }
    setPortadaName("Seleccionar un archivo");
  };

  useEffect(() => {
    if (edit) {
      setFileName(data?.fileContenido);
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setFileName("Seleccionar un archivo");
        setPortadaName("Seleccionar un archivo");
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdropFilter: "blur(8px)" }}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0)" } }}
    >
      <div className="ModalContent">
        <h2>{edit ? "Editar Contenido" : "Agregar nuevo contenido"}</h2>
        <InputLabel htmlFor="Nombre" sx={{ width: "100%" }}>
          Nombre del contenido
          <OutlinedInput
            required
            id="Nombre"
            placeholder="Contenido"
            defaultValue={data.nombreContenido}
            onChange={(e) => {
              setData({
                ...data,
                nombreContenido: e.target.value,
              });
            }}
          />
        </InputLabel>
        <label htmlFor="description">
          Descripción del contenido
          <textarea
            name="description"
            id="description"
            cols="30"
            rows="10"
            defaultValue={data.descripcionContenido}
            placeholder="Descripcion"
            onChange={(e) => {
              setData({
                ...data,
                descripcionContenido: e.target.value,
              });
            }}
          ></textarea>
        </label>
        <div className="ModalContent__files">
          <div className="ModalContent__filesFile">
            <h3>Subir archivo de contenido</h3>
            <input
              type="file"
              name="file"
              id="file"
              accept=".pdf"
              onChange={(e) => {
                const file = e.target.files[0].name;
                setData({
                  ...data,
                  fileContenido: file,
                  formatoContenido: `.${getFileExtension(file)}`,
                });
                setFileName(file);
                setFile(e.target.files[0]);
              }}
            />
            <label htmlFor="file">
              <FontAwesomeIcon
                icon={faCloudUpload}
                className="ModalContent__filesFileIcon"
              />
              {fileName}
            </label>
          </div>
          <div className="ModalContent__filesFile">
            <h3>Subir portada</h3>
            <input
              type="file"
              name="image"
              id="image"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0].name;
                setData({
                  ...data,
                  imgContenido: file,
                });
                setPortadaName(file);
                setPortada(e.target.files[0]);
              }}
            />
            <label htmlFor="image">
              <FontAwesomeIcon
                icon={faImage}
                className="ModalContent__filesFileIcon"
              />
              {portadaName}
            </label>
          </div>
        </div>
        <div className="ModalClients__buttons">
          <button
            onClick={(e) => {
              e.preventDefault();
              action();
              uploadFile();
              uploadImage();
            }}
          >
            {edit ? "Editar contenido" : "Crear contenido"}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalContent;
