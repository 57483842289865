import "./TableUsers.scss";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { TablePagination } from "@mui/material";

const TableUsers = ({ colums, rows, deleteOpt }) => {
  //pagination config
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const indexLastData = (page + 1) * rowsPerPage;
  const indexFirstData = indexLastData - rowsPerPage;
  const currentData = rows.slice(indexFirstData, indexLastData);

  const blueCell = index =>
    index % 2 !== 0 ? { backgroundColor: "#00ccff20" } : null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <section>
      <table className="tableUsers">
        <thead>
          <tr>
            {colums.map((row, index) => (
              <th key={index}>{row}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData.map((row, index) => (
            <tr key={row.id}>
              <th
                style={blueCell(index)}
              >{`${row.nombreUsuario} ${row.apellidoUsuario}`}</th>
              <th style={blueCell(index)}>{row.emailUsuario}</th>
              <th style={blueCell(index)}>{row.created_at}</th>
              <th style={blueCell(index)}>{row.deleted_at}</th>
              <th style={blueCell(index)}>{row.updated_at}</th>
              <th style={blueCell(index)}>{row.ciudadUsuario}</th>
            </tr>
          ))}
        </tbody>
      </table>
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ color: "#0A8FEC" }}
        labelRowsPerPage={"Filas por pagina"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
        }
      />
    </section>
  );
};

export default TableUsers;
