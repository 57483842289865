import React, { useState } from "react";
import Switch from "react-switch";

const SwitchSelector = (props) => {
  const [checked, setCheked] = useState (false);
  
  const handleChange = ()=> {
   setCheked(!checked);
  }
  return (
    <>
        <Switch
          checked={checked}
          onChange={handleChange}
          handleDiameter={40}
          offColor="#010194"
          onColor="#010194"
          offHandleColor="#0A8FEC"
          onHandleColor="#0A8FEC"
          height={50}
          width={100}
          borderRadius={30}          
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 20,
                color: "#D2DCE3",
                paddingRight: 2,
              }}
            >
              {props.on}
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 20,
                color: "#D2DCE3",
                paddingRight: 2,
              }}
            >
              {props.off}
            </div>
          }
          className="react-switch"
          id="small-radius-switch"
        />
      </>
  )
}

export default SwitchSelector
