import { faCloudDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./Download.scss";

function Download({ nombre, documento }) {
  return (
    <a
      href={documento}
      target="_blank"
      rel="noopener noreferrer"
      download={documento}
    >
      <button className="Card">
        <h3>{nombre}</h3>
        <div className="DownloadBt">
          <FontAwesomeIcon className="Icon" icon={faCloudDownload} />
        </div>
      </button>
    </a>
  );
}

export default Download;
