import "./AdminNavigation.scss";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faKey,
  faQrcode,
  faArrowRightFromBracket,
  faHomeUser
} from "@fortawesome/free-solid-svg-icons";

const AdminNavigation = ({ userData = null }) => {
  const [dropMenu, setDropMenu] = useState(false); 
  const {
    apellidoUsuario,
    nombreUsuario,
  } = userData[0];

  const killSession = () => {
    localStorage.clear();
  };

  return (
    <nav className="adminNav">
      <div className="adminNav__welcome">
        <p>Bienvenido/a</p>
        <h4>{`${nombreUsuario} ${apellidoUsuario}`}</h4>
      </div>
      <div className="adminNav__menu">
        <Link to="/tknovaRoot" className="adminNav__menuButton">
          <FontAwesomeIcon icon={faHome} className="adminNav__menuButtonIcon" />
          Resumen
        </Link>
        <div>
          <h5 onClick={() => setDropMenu(!dropMenu)}>
            <FontAwesomeIcon
              icon={faKey}
              className="adminNav__menuButtonIcon"
            />
            Administrar
          </h5>
          {dropMenu && (
            <ul>
              <li>
                <Link to="/tknovaRoot/clientes">Clientes</Link>
              </li>
              <li>
                <Link to="/tknovaRoot/Instituciones">Instituciones</Link>
              </li>
              <li>
                <Link to="/tknovaRoot/contenido">Contenido</Link>
              </li>
              <li>
                <Link to="/tknovaRoot/usuarios">Usuarios</Link>
              </li>
            </ul>
          )}
        </div>
        <Link to="/tknovaRoot/Generador" className="adminNav__menuButton">
          <FontAwesomeIcon
            icon={faQrcode}
            className="adminNav__menuButtonIcon"
          />
          Codigos
        </Link>
        <Link to="/" className="adminNav__menuButton">
          <FontAwesomeIcon
            icon={faHomeUser}
            className="adminNav__menuButtonIcon"
          />
          Home
        </Link>
      </div>
      <div className="adminNav__logOut">
        <Link to="/login"  onClick={killSession}>
          <FontAwesomeIcon
            icon={faArrowRightFromBracket}
            className="navigation__buttonsIcon"
          />
          Cerrar sesión
        </Link>
        <span>Versión 1.0.0</span>
      </div>
    </nav>
  );
};

export default AdminNavigation;
