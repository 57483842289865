import { faBookmark, faStar, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Home_welcome from "../../assets/img/Home_welcome_img.svg";
import ContentCard from "../../components/ContentCard/ContentCard";
import SwitchSelector from "../../components/Switch/SwitchSelector";
import { configAxios, ROUTES_INSTITUCIONS } from "../../utils/AxiosAPI";
import "./Home.scss";
import { Link } from "react-router-dom";
import { dateVerification } from "../../utils/utils";

const Home = () => {
    const userLocalStorage = localStorage.getItem("usuario")
        ? JSON.parse(localStorage.getItem("usuario"))
        : null;

    const [institucion, setInstitucion] = useState({});
    const [contenidosTotales, setContenidosTotales] = useState([]);
    const [result, setResult] = useState([]);
    const [ordenar, setOrdenar] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    const [isExpiratedDate, setisExpiratedDate] = useState(false);

    const getContenidosIds = async () => {
        try {
            const res = await axios.get(
                `${ROUTES_INSTITUCIONS.getByID}${userLocalStorage[0].instituciones_id}`,
                configAxios,
            );
            setContenidosTotales(res?.data?.data?.contenidos);
            setResult(res?.data?.data?.contenidos);
            setInstitucion(res?.data?.data);
            setisExpiratedDate(dateVerification(res?.data?.data?.final));
        } catch (error) {
            console.log(error);
        }
    };

    if (ordenar) {
        contenidosTotales?.sort((a, b) => a.nombreContenido.localeCompare(b.nombreContenido));
    } else {
        contenidosTotales?.sort((a, b) => b.nombreContenido.localeCompare(a.nombreContenido));
    }

    const ultimoContenido = contenidosTotales?.find(
        e => e.id === userLocalStorage[0].ultimoContenido,
    );

    function handleSearch(event) {
        setSearchTerm(event.target.value);
        const searchTerm = event.target.value.toLowerCase(); // Obtener el texto de búsqueda en minúsculas
        const resultados = contenidosTotales?.filter(contenido => {
            // Filtrar los contenidos basados en el término de búsqueda
            const nombre = contenido.nombreContenido.toLowerCase();
            return nombre.includes(searchTerm);
        });
        setResult(resultados);
    }

    const clearSearch = () => {
        setSearchTerm("");
        setResult(contenidosTotales);
    };

    useEffect(() => {
        getContenidosIds();
    }, []);

    return (
        <main className="Home">
            <header>
                <section className="NameCard">
                    <p className="Welcome">
                        {userLocalStorage[0].generoUsuario === "Femenino"
                            ? "Bienvenida,"
                            : "Bienvenido,"}
                    </p>
                    <h2>{userLocalStorage[0].nombreUsuario}!</h2>
                    <p>Este es tu dashboard</p>
                    <img
                        className="Imagen"
                        src={Home_welcome}
                        alt=""
                    />
                </section>
                <Link to={`/content/${userLocalStorage[0].ultimoContenido}`}>
                    <section className="RecentContent">
                        <p>
                            <FontAwesomeIcon icon={faStar} /> Vuelve a tu contenido más reciente:
                        </p>
                        <h3>{ultimoContenido?.nombreContenido}</h3>
                    </section>
                </Link>
            </header>
            <section>
                <div className="Filtros">
                    <section>
                        <FontAwesomeIcon icon={faBookmark} /> Tus contenidos
                    </section>
                    <section className="Barra-Contenedor">
                        <section className="Barra-Busqueda">
                            <input
                                type="text"
                                placeholder="Buscar..."
                                onChange={handleSearch}
                                value={searchTerm}
                            />
                            {searchTerm && (
                                <button
                                    className="X-boton"
                                    onClick={clearSearch}>
                                    X
                                </button>
                            )}
                            <FontAwesomeIcon icon={faSearch}>Buscar</FontAwesomeIcon>
                        </section>
                    </section>
                </div>

                <div className="Content-Card">
                    {isExpiratedDate ? (
                        <h4>Tus contenidos han expirado</h4>
                    ) : result.activo ? (
                        <h4>No tienes contenidos activos</h4>
                    ) : result.length === 0 ? (
                        <h4>No hay resultados para la búsqueda</h4>
                    ) : (
                        result
                            .filter(i => i.activo === 1 || i.activo === "1")
                            .map(i => {
                                const link = `/content/${i.id}`;
                                return (
                                    <ContentCard
                                        key={i.id}
                                        content={i}
                                        link={link}
                                    />
                                );
                            })
                    )}
                </div>
            </section>
        </main>
    );
};

export default Home;
