import "./ModalInstitucion.scss";
import React, { useEffect, useState } from "react";
import {
  Modal,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { fmtCities, fmtDeparments } from "../../utils/utils";
import { CssAutocomplete, CssTextField } from "./CssAutocomplete";
import { createFilterOptions } from "@mui/material";

const ModalInstitucion = ({
  open,
  handleClose,
  action,
  edit = false,
  setInstitucion,
  institucionData = false,
  options,
}) => {
  const [deparmetsList, setDepartmentsList] = useState([]);
  const [citiesList, setCitiestsList] = useState([]);
  const [department, setDepatment] = useState("");
  const [city, setcity] = useState("");
  const [autocoCompleteValue, setAutoCompleteValue] = useState("");

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, 4);
  };

  const getDeparments = async () => {
    try {
      const res = await axios.get(
        "https://raw.githubusercontent.com/marcovega/colombia-json/master/colombia.min.json"
      );
      const deparments = fmtDeparments(res.data);
      const cities = fmtCities(res.data, department);
      setDepartmentsList(deparments);
      setCitiestsList(cities);
    } catch (err) {
      console.log(err);
    }
  };

  const seachLabel = () => {
    const client = options.find(i => i.id === edit.clientes_id);
    return edit ? client.label : "Cliente";
  };

  useEffect(() => {
    if (!edit) {
      setAutoCompleteValue("");
      setDepatment("");
      setcity("");
    }
  }, [open]);

  useEffect(() => {
    getDeparments();
  }, [department]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdropFilter: "blur(8px)" }}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0)" } }}
    >
      <div className="ModalInstitucion">
        <h2>{edit ? "Editar institucion" : "Agregar nueva institucion"}</h2>
        <InputLabel htmlFor="Nombre" sx={{ width: "100%" }}>
          Nombre de la institución
          <OutlinedInput
            id="Nombre"
            placeholder="Institución"
            defaultValue={institucionData.nombreInstitucion}
            className="signIn__formContainerInput signIn__formContainerInputMid"
            onChange={e => {
              setInstitucion({
                ...institucionData,
                nombreInstitucion: e.target.value,
              });
            }}
          />
        </InputLabel>
        <InputLabel htmlFor="Nombre" sx={{ width: "100%" }}>
          Nombre del cliente
          <CssAutocomplete
            filterOptions={filterOptions}
            id="search"
            options={options}
            disableListWrap={true}
            value={autocoCompleteValue}
            isOptionEqualToValue={(option, value) =>
              option.title === value.title
            }
            onChange={(event, newValue) => {
              setAutoCompleteValue(newValue !== null ? newValue.label : "");
              setInstitucion({
                ...institucionData,
                clientes_id: newValue.id,
              });
            }}
            renderInput={params => (
              <CssTextField
                {...params}
                placeholder={seachLabel()}
                sx={{ fontSize: "12px" }}
              />
            )}
            size="small"
          />
        </InputLabel>
        <InputLabel htmlFor="Nombre" sx={{ width: "100%" }}>
          Numero del proyecto
          <OutlinedInput
            id="Nombre"
            placeholder="Numero del proyecto"
            className="signIn__formContainerInput signIn__formContainerInputMid"
            defaultValue={institucionData.numeroProyecto}
            onChange={e => {
              setInstitucion({
                ...institucionData,
                numeroProyecto: e.target.value,
              });
            }}
          />
        </InputLabel>
        <div className="ModalClients__place">
          <InputLabel
            htmlFor="departamento"
            className="signIn__formContainerLabelDeparment"
          >
            Departamento
            <Select
              displayEmpty
              id="departamento"
              value={department}
              className="signIn__formContainerInputSelect"
              size="small"
              renderValue={selected => {
                if (selected.length === 0 && institucionData) {
                  return <em>{institucionData.departamentoInstitucion}</em>;
                } else {
                  return <em>{department}</em>;
                }
              }}
            >
              {deparmetsList.map((i, index) => (
                <MenuItem
                  key={index}
                  value={i}
                  onClick={() => {
                    setDepatment(i);
                    setcity("");
                    setInstitucion({
                      ...institucionData,
                      departamentoInstitucion: i,
                    });
                  }}
                >
                  {i}
                </MenuItem>
              ))}
            </Select>
          </InputLabel>

          <InputLabel
            htmlFor="ciudad"
            className="signIn__formContainerLabelCity"
          >
            Ciudad
            <Select
              displayEmpty
              id="ciudad"
              value={city}
              className="signIn__formContainerInputSelect"
              size="small"
              renderValue={selected => {
                if (selected.length === 0 && institucionData) {
                  return <em>{institucionData.ciudadInstitucion}</em>;
                } else {
                  return <em>{city}</em>;
                }
              }}
            >
              {citiesList.map((i, index) => (
                <MenuItem
                  key={index}
                  value={i}
                  onClick={() => {
                    setcity(i);
                    setInstitucion({
                      ...institucionData,
                      ciudadInstitucion: i,
                    });
                  }}
                >
                  {i}
                </MenuItem>
              ))}
            </Select>
          </InputLabel>
        </div>
        <div className="ModalInstitucion__date">
          <label htmlFor="dateStart">
            Fecha de inicio
            <input
              type="date"
              name="date"
              id="dateStart"
              defaultValue={institucionData.inicio}
              onChange={e => {
                setInstitucion({
                  ...institucionData,
                  inicio: e.target.value,
                });
              }}
            />
          </label>
          <label htmlFor="dateEnd">
            Fecha final
            <input
              type="date"
              name="date"
              id="dateEnd"
              defaultValue={institucionData.final}
              onChange={e => {
                setInstitucion({
                  ...institucionData,
                  final: e.target.value,
                });
              }}
            />
          </label>
        </div>
        <div className="ModalClients__buttons">
          <button
            onClick={e => {
              e.preventDefault();
              action();
            }}
          >
            {edit ? "Editar institucion" : "Crear institucion"}
          </button>
          <button
            onClick={e => {
              e.preventDefault();
              handleClose();
            }}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalInstitucion;
