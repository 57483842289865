import React, { useEffect } from "react";
import "./UserLayoud.scss";
import AppNavigation from "../../components/AppNavigation/AppNavigation";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Logo from "../../components/Logo/Logo";

function UserLayoud() {
  const userLocalStorage = localStorage.getItem("usuario")
    ? JSON.parse(localStorage.getItem("usuario"))
    : null;

  const navigate = useNavigate();

  const userVerification = userLocalStorage === null;

  useEffect(() => {
    if (userVerification) {
      navigate("/login");
    }
  }, []);

  if (!userVerification) {
    return (
      <section className="userLayoud">
        <Logo forPage="users" />
        <AppNavigation userData={userLocalStorage} />
        <main className="userLayoud__Content">
          <Outlet />
          <Footer />
        </main>
      </section>
    );
  }
}

export default UserLayoud;
