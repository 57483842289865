import "./Stadistic.scss";
import React from "react";

const Stadistic = ({ data, text, type = "", fs }) => {
  return (
    <div
      className={`stadistic ${type}`}
      style={{ fontSize: `${fs}px`, padding: `${fs * 1.5}px` }}
    >
      <h2>{data}</h2>
      <p style={{ fontSize: `${fs / 1.3}px` }}>{text}</p>
    </div>
  );
};

export default Stadistic;
