import React from "react";
import "./Footer.scss";

function Footer() {
    return (
        <footer className="footer">
            <div>
                Todos los Derechos Reservados D.R.A. Prohibida su reproducción total o parcial, así
                como su traducción a cualquier idioma sin la autorización escrita de su titular.
                Dirección: KR 16 # 97 - 46 TO 97 PI 7. Reproduction in whole or in part is
                prohibited. All rights reserved copyright ©{new Date().getFullYear()}. Si tienes una
                rectificación o aclaración sobre algún tema publicado en este medio, por favor
                escríbenos a tknova-academy@grupotknova.com
            </div>
        </footer>
    );
}

export default Footer;
