import "./MailForm.scss";
import React from "react";
import { fmtCodeList2, delay } from "../../utils/utils";
import { useState } from "react";
import axios from "axios";
import { configAxios, ROUTES_MAIL } from "../../utils/AxiosAPI";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";

const MailForm = ({ codesNewList, fechaCaducidad, institucion }) => {
  const [mailData, setMailData] = useState({});
  const [alert, setAlert] = useState(false);
  const [sent, setSent] = useState(false);

  const navigate = useNavigate();
  const sendMail = async () => {
    try {
      const res = await axios.post(
        ROUTES_MAIL.sendCodes,
        {
          to: mailData.email,
          subject: mailData.subject,
          text: `${
            mailData?.text ? mailData?.text : ""
          }<br><br>Sus códigos serán validos hasta el: <br><b>${fechaCaducidad}</b><br><br>${fmtCodeList2(
            codesNewList
          )}`,
          institucion: `${institucion}`,
        },
        configAxios
      );
      if (res.statusText === "OK") {
        setSent(true);
        setTimeout(() => {
          navigate("/tknovaRoot");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form className="MailForm">
      <p>Enviar via correo electrónico:</p>
      <div>
        <input
          type="email"
          name="to"
          placeholder="Para : default@example.com"
          autoComplete="off"
          required
          multiple
          onChange={(e) => {
            setMailData({
              ...mailData,
              email: e.target.value,
            });
          }}
        />
        <button
          disabled={Object.keys(mailData).length === 0}
          type="submit"
          onClick={ async (e) => {
            e.preventDefault();
            mailData.email.includes("@") ? sendMail() : setAlert(true);
            await delay(4000);
            setAlert(false);
          }}
        >
          Enviar
        </button>
      </div>
      <input
        type="text"
        name="subject"
        placeholder="Asunto"
        autoComplete="off"
        onChange={(e) => {
          setMailData({
            ...mailData,
            subject: e.target.value,
          });
        }}
      />
      <textarea
        name="subject"
        cols="30"
        rows="10"
        placeholder= {`Contenido del correo... (Los códigos generados ya están incluidos en el contenido de este correo)`}
        onChange={(e) => {
          setMailData({
            ...mailData,
            text: e.target.value,
          });
        }}
      ></textarea>
      {alert && (
        <Alert severity="error">
          Debes escribir un correo valido para enviar los códigos
        </Alert>
      )}
      {sent && <Alert>Correo enviado exitosamente!</Alert>}
    </form>
  );
};

export default MailForm;
