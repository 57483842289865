class ViewSDKClient {
    constructor() {
        this.readyPromise = new Promise(resolve => {
            if (window.AdobeDC) {
                resolve();
            } else {
                /* Wait for Adobe Document Services PDF Embed API to be ready */
                document.addEventListener("adobe_dc_view_sdk.ready", () => {
                    resolve();
                });
            }
        });
        this.adobeDCView = undefined;
    }

    ready() {
        return this.readyPromise;
    }

    previewFile(divId, viewerConfig, fileName, url) {
        const config = {
            clientId: `${process.env.REACT_APP_ACROBAT_TOKEN}`,
        };
        if (divId) {
            config.divId = divId;
        }

        config.locale = "es-ES";
        this.adobeDCView = new window.AdobeDC.View(config);
        //Para usar con promesa
        // function fetchPDF(urlToPDF) {
        //   return new Promise((resolve) => {
        //     fetch(urlToPDF)
        //       .then((resolve) => resolve.blob())
        //       .then((blob) => {
        //         resolve(blob.arrayBuffer());
        //       });
        //   });
        // }
        /* Invoke the file preview API on Adobe DC View object */
        const previewFilePromise = this.adobeDCView.previewFile(
            {
                /* Pass information on how to access the file */
                content: {
                    /* Location of file where it is hosted */
                    location: {
                        url: url,
                    },
                },
                /* Pass meta data of file */
                metaData: {
                    /* file name */
                    fileName: fileName,
                },
            },
            viewerConfig,
        );

        return previewFilePromise;
    }
}

export default ViewSDKClient;
