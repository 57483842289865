import "./CodesGenerator.scss";
import React, { useEffect, useState } from "react";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import Search from "../../components/Search/Search";
import CodeForm from "../../components/CodeForm/CodeForm";
import MailForm from "../../components/MailForm/MailForm";
import CodeTag from "../../components/CodeTag/CodeTag";
import {
  faTag,
  faClockRotateLeft,
  faHourglassStart,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
  configAxios,
  ROUTES_INSTITUCIONS,
  RUOTES_CLIENTS,
  ROUTES_CODIGOS,
} from "../../utils/AxiosAPI";
import { fmtCodeList, fmtOptionsAutoComplete } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CodesGenerator = () => {
  const [InstitucionesList, setInstitucionesList] = useState([]);
  const autoCompleteOptions = fmtOptionsAutoComplete(
    InstitucionesList,
    "nombreInstitucion"
  );
  const [autocoCompleteValue, setAutoCompleteValue] = useState("");
  const [institucionData, setInstitucionData] = useState(null);
  const [clientenData, setClienteData] = useState(null);
  const [codes, setCodes] = useState([]);
  const [mailDisabled, setMailDisabled] = useState(true);
  const [codesNewList, setCodesNewList] = useState([]);
  const [fechaCaducidad, setFechaCaducidad] = useState("");
  const [infoInstitucion, setInfoInstitucion] = useState([]);

  const getInstituciones = async () => {
    try {
      const instituciones = await axios.get(
        ROUTES_INSTITUCIONS.getAllWhitUsers,
        configAxios
      );
      setInstitucionesList(instituciones.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getClient = async () => {
    try {
      const cliente = await axios.get(
        `${RUOTES_CLIENTS.getByID}${institucionData.clientes_id}`,
        configAxios
      );
      setClienteData(cliente.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCodes = async () => {
    try {
      const codigos = await axios.get(ROUTES_CODIGOS.getAll, configAxios);
      const data = codigos?.data?.data.filter(
        (i) => i.instituciones_id === institucionData.id
      );
      setCodes(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getInstituciones();
  }, []);

  useEffect(() => {
    const institucion = InstitucionesList.find(
      (i) => i.nombreInstitucion === autocoCompleteValue
    );
    autocoCompleteValue !== "" && setInstitucionData(institucion);
    setInfoInstitucion(institucion);
  }, [autocoCompleteValue]);

  useEffect(() => {
    institucionData !== null && getClient();
    institucionData !== null && getCodes();
  }, [institucionData]);

  return (
    <main className="codesGenerator">
      <HeaderAdmin title="Gestión de códigos" bgColor="#5800FF" />
      <section className="codesGenerator__content">
        <div className="codesGenerator__contentSearch">
          <Search
            label="Usa esta barra para buscar una institución por nombre"
            options={autoCompleteOptions}
            autocoCompleteValue={autocoCompleteValue}
            setAutoCompleteValue={setAutoCompleteValue}
          />
        </div>
        <div className="codesGenerator__contentForms">
          <h1>Generador de códigos</h1>
          <div>
            <div className="codesGenerator__contentFormsCodes">
              <CodeForm
                institucionData={institucionData}
                getCodes={getCodes}
                setMailDisabled={setMailDisabled}
                setCodesNewList={setCodesNewList}
                setFechaCaducidad={setFechaCaducidad}
              />
            </div>
            <div className="codesGenerator__contentFormsMail">
              {!mailDisabled ? (
                <MailForm
                  codesNewList={codesNewList}
                  fechaCaducidad={fechaCaducidad}
                  institucion={infoInstitucion.nombreInstitucion}
                />
              ) : (
                <h4 className="codesGenerator__contentFormsMailText">
                  Genera codigos para poder enviarlos.
                </h4>
              )}
            </div>
          </div>
        </div>
        {!mailDisabled && (
          <div className="codesGenerator__contentCodesList">
            <h2>
              Lista de codigos generada:
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(fmtCodeList(codesNewList));
                }}
              >
                <FontAwesomeIcon icon={faCopy} />
                copiar
              </button>
            </h2>
            <ul>
              {codesNewList.map((codigo, index) => {
                return (
                  <li key={codigo}>
                    <span>Código No.{index + 1}:</span>
                    {codigo}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {institucionData !== null && (
          <div className="codesGenerator__contentStadistics">
            <h4>
              <span>Cliente: </span>
              {clientenData?.nombreCliente}
            </h4>
            <h2>
              <span>Institución: </span>
              {institucionData?.nombreInstitucion}
            </h2>
            <div>
              <CodeTag
                data={codes.filter((i) => i.emailVinculado !== null).length}
                icon={faTag}
                txt={"Códigos canjeados"}
              />
              <CodeTag
                data={codes.filter((i) => i.emailVinculado === null).length}
                icon={faHourglassStart}
                txt={"Códigos sin canjear"}
              />
              <CodeTag
                data={codes.length}
                icon={faClockRotateLeft}
                txt={"Generados para esta institucion"}
              />
            </div>
          </div>
        )}
      </section>
    </main>
  );
};

export default CodesGenerator;
