import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./IframeContainer.scss";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IframeContainer = ({ button, src, id }) => {
  const userLocalStorage = localStorage.getItem("usuario")
    ? JSON.parse(localStorage.getItem("usuario"))
    : null;

  const navigate = useNavigate();

  const userVerification = userLocalStorage === null;

  useEffect(() => {
    if (userVerification) {
      navigate("/login");
    }
  }, []);

  if (!userVerification) {
    return (
      <div className="IframeContainer">
        {button && (
          <Link to={`/content/${id}`}>
            <FontAwesomeIcon className="Icon" icon={faCircleArrowLeft} />
            Volver
          </Link>
        )}
        <iframe src={src} frameBorder="0"></iframe>
      </div>
    );
  }
};

export default IframeContainer;
