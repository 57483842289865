import React from "react";
import "./Loading.scss";
import logo from "../../assets/img/LogoIMG.png";

const Loading = () => {
  return (
    <figure className="loading">
      <img src={logo} alt="Tknova-logo" />
    </figure>
  );
};

export default Loading;
